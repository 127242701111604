import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';
import { ListItens } from '../../components/layout/ListItens';
import { useListContext } from '../../context/ListContext.jsx';
import { useAuthContext } from '../../context/AuthContext';
import CheckboxList from '../../components/layout/checkboxList';
import sweetAlert from '../../components/layout/sweetAlert.js';
import BuscaFiliaisApi from '../../api/buscaFiliaisApi';

const cadAtivos = () => {
  const [idativo, setidativo] = useState('');
  const [nomeativo, setnomeativo] = useState('');
  const [AlteracaoVinculo, setAlteracaoVinculo] = useState(false);
  const [tipo, settipo] = useState('E');
  const [dadosGridAtivos, setdadosGridAtivos] = useState([]);
  const [listaAtivos, setListaAtivos] = useState([]);
  const [filiais, setFiliais] = useState([]);
  const [selectedFilial, setSelectedFilial] = useState(-1);

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const [TextBotao2, setTextBotao2] = useState('Salvar');
  const [TipoBotao2, setTipoBotao2] = useState('btn btn-success');

  const { Opreracao, setOpreracao, selectedOptions, setSelectedOptions } =
    useListContext();

  const { userLogin, payload } = useAuthContext();

  const handleCheckboxChange = (selectedOptions) => {
    //console.log('Opções selecionadas:', selectedOptions);
  };

  useEffect(function () {
    carregaGridAtivos();
    carregaListAtivos();
    carregaUnidades();
  }, []);

  useEffect(
    function () {
      VerificaOperacao();
    },
    [Opreracao]
  );

  async function VerificaOperacao() {
    let ok;
    let dados = Opreracao;

    if (dados !== '') {
      ok = await CarregaDados(dados);
      setOpreracao('');
    }
  }

  async function carregaListAtivos() {
    let Ativos = [];

    let retorno = await BuscaAtivos();
    retorno.forEach((element) => {
      Ativos.push({
        id: element.IDativo,
        titulo:
          element.nome.charAt(0).toUpperCase() + element.nome.substring(1),
        //DEIXAR PRIMEIRA LETRA MAIUSCULA
        //string.charAt(0).toUpperCase() + string.substring(1);
      });
    });

    setListaAtivos(Ativos);
  }

  async function carregaUnidades() {
    const response = await BuscaFiliaisApi();

    if (!!response) {
      const filiaisOrdenadas = [...response].sort((a, b) =>
        a.cidade.localeCompare(b.cidade)
      );
      setFiliais(filiaisOrdenadas);
    }
  }

  async function CarregaDados(Objeto) {
    let retorno;

    retorno = await BuscaAtivoID(Objeto.Id);

    setidativo(retorno[0].IDativo);
    setnomeativo(retorno[0].nome);
    settipo(retorno[0].tipo);

    if (Objeto.Operacao === 'Deletar') {
      setTextBotao('Deletar');
      setTipoBotao('btn btn-danger');
    } else {
      setTextBotao('Alterar');
      setTipoBotao('btn btn-secondary');
    }
  }

  async function BuscaAtivos() {
    return await api
      .get('/Ativos')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaMenus() {
    return await api
      .get('/Menus')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async function BuscaAtivoID(Id) {
    return await api
      .get('/AtivosID/' + Id)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function deleteAtivo() {
    return await api
      .delete('/Ativos/' + idativo)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function deleteVinculoAtivo(IDfilial) {
    return await api
      .delete('/AtivosFiliais/' + IDfilial)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvaAlteraAtivo() {
    let retorno;
    if (TextBotao === 'Deletar') {
      if (
        window.confirm(
          'Essa opção vai DELETAR definitivamente esse cadastro. \n\nClique OK para Prosseguir.'
        )
      ) {
        retorno = await deleteAtivo();
        carregaGridAtivos();
        carregaListAtivos();
        LimparTela();
      }
    } else {
      if (idativo === '') {
        retorno = await InserirAtivo();
        carregaGridAtivos();
        carregaListAtivos();
        LimparTela();
      } else {
        retorno = await AlterarAtivo();
        carregaGridAtivos();
        carregaListAtivos();
        LimparTela();
      }
    }
  }

  async function InserirAtivo() {
    let dadosPost = {
      nome: nomeativo,
      tipo: tipo,
    };

    if (!nomeativo || !tipo) {
      console.log('nomeativo', nomeativo);
      sweetAlert('warning', 'Atenção', 'Preencha todos os campos!');
      return;
    }

    return await api
      .post('/Ativos', dadosPost)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function AlterarAtivo() {
    const dados = {
      IDativo: idativo,
      nome: nomeativo,
      tipo: tipo,
    };

    try {
      return await api
        .put('/Ativos', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function carregaGridAtivos() {
    let ativos = [];

    let retorno = await BuscaAtivos();
    retorno.forEach((element) => {
      ativos.push({
        id: element.IDativo,
        coluna2: element.nome,
        coluna3: element.tipo === 'S' ? 'SAÍDA' : 'ENTRADA',
      });
    });

    setdadosGridAtivos(ativos);
  }

  function LimparTela() {
    setidativo('');
    setnomeativo('');
    settipo('E');
    setTextBotao('Salvar');
    setTipoBotao('btn btn-success');
  }

  const handleSelectChange = async (event) => {
    const valorSelecionado = parseInt(event.target.value);
    setSelectedFilial(valorSelecionado);
    if (valorSelecionado == 0) {
      setSelectedFilial(-1);
      setAlteracaoVinculo(false);
    } else {
      let isalteracao = false;
      let dados = [];
      let retorno = await BuscaAtivosFilial(parseInt(event.target.value));
      retorno.map(function (l) {
        dados.push(l.IDativo);
        isalteracao = true;
      });
      setSelectedOptions(dados);
      setAlteracaoVinculo(isalteracao);
    }
  };

  async function BuscaAtivosFilial(IDfilial) {
    return await api
      .get('/AtivosFiliais/' + IDfilial)
      .then(function (response) {
        console.log('rotoenoo+++> ' + response.data[0]);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  function ExibeFilial() {
    return (
      <select
        onChange={(e) => handleSelectChange(e)}
        style={{
          borderRadius: '5px',
          width: '100%',
          height: '30px',
          padding: '2px',
        }}
        id="dropdown"
        value={selectedFilial}
      >
        <option key="-1" value="-1">
          -- SELECIONE UMA OPÇÃO --
        </option>
        {filiais.length > 0 &&
          filiais.map((filial) => (
            <option key={filial.IDfilial} value={filial.IDfilial}>
              {filial.cidade} - {filial.uf}
            </option>
          ))}
      </select>
    );
  }

  const handleTipoChange = (event) => {
    settipo(event.target.value);
  };

  async function LimparVinculo() {
    setSelectedFilial(-1);
    setSelectedOptions([]);
  }

  async function InsertVinculoAtivos(IDfilial, IDativo) {
    let dadosPost = {
      IDfilial: IDfilial,
      IDativo: IDativo,
    };

    return await api
      .post('/AtivosFiliais', dadosPost)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvarVinculoFilial() {
    if (selectedFilial > 0) {
      await AtualizaAtivos(selectedFilial);
      LimparVinculo();
    }
  }

  async function AtualizaAtivos(IDfilial) {
    let retorno;

    retorno = await deleteVinculoAtivo(IDfilial);
    if (retorno.Delete === 'OK') {
      selectedOptions.map(function (id_) {
        InsertVinculoAtivos(IDfilial, id_);
      });
      return { Permissoes: 'OK' };
    } else {
      return { Error: 'Atualização não realizada(DELETE)' };
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        width: '100%',
      }}
    >
      <div
        className="row"
        style={{
          padding: '2%',
          paddingBottom: '0',
          backgroundColor: 'rgb(243, 240, 231)',
        }}
      >
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div style={{ backgroundColor: 'rgb(243, 240, 231)', width: '100%' }}>
            <div
              style={{
                padding: '2%',
                paddingBottom: '0',
                backgroundColor: 'white',
                alignItems: 'center',
                padding: '15px',
                width: '100%',
              }}
            >
              <legend style={{ textAlign: 'center' }}>
                VÍNCULO - ATIVOS POR FRANQUIA
              </legend>
              <div style={{ width: '100%' }}>
                <input
                  id="Alteracao"
                  style={{ display: 'none' }}
                  type="text"
                  value={AlteracaoVinculo}
                  onChange={(e) => setidativo(e.target.value)}
                />

                <label>Franquia:</label>
                <br />
                <ExibeFilial></ExibeFilial>
                <br />
                <br />
              </div>

              <div>
                <CheckboxList
                  options={listaAtivos}
                  onCheckboxChange={handleCheckboxChange}
                  titulo={'ATIVOS'}
                />
              </div>

              <div>
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao2}
                      type="button"
                      value={TextBotao2}
                      style={{ color: 'black' }}
                      onClick={() => SalvarVinculoFilial()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      style={{ color: 'black', borderColor: '#d3d3d3' }}
                      onClick={() => LimparVinculo()}
                    />
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>

          <br />

          <div id="formulario">
            <form id="formulario" autoComplete="off">
              <fieldset>
                <legend>CADASTRO - ATIVOS</legend>
                <input
                  id="idativo"
                  style={{ display: 'none' }}
                  type="text"
                  value={idativo}
                  onChange={(e) => setidativo(e.target.value)}
                />
                <br />
                <br />
                <label>Ativo:</label>
                <br />
                <input
                  id="nomeativo"
                  className="width_cemporcento  form-control"
                  type="text"
                  value={nomeativo}
                  onChange={(e) => setnomeativo(e.target.value)}
                />
                <br />
                <br />
                <label>Tipo:</label>
                <div
                  style={{
                    padding: '15px',
                    backgroundColor: 'rgb(241, 239, 236)',
                  }}
                >
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="E"
                        checked={tipo === 'E'}
                        onChange={handleTipoChange}
                      />
                      Entrada
                    </label>
                  </div>
                  <div className="radio">
                    <label>
                      <input
                        type="radio"
                        value="S"
                        checked={tipo === 'S'}
                        onChange={handleTipoChange}
                      />
                      Saída
                    </label>
                  </div>
                </div>

                <br />
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao}
                      type="button"
                      value={TextBotao}
                      onClick={() => SalvaAlteraAtivo()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      onClick={() => LimparTela()}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <ListItens
        titulogeral={'ATIVOS CADASTRADOS'}
        nomeColuna1={'ID'}
        nomeColuna2={'NOME'}
        nomeColuna3={'TIPO'}
        dadosGrid={dadosGridAtivos}
        tipo={'1'}
      />
    </div>
  );
};

export default cadAtivos;
