import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

const CartProvider = ({children}) => {
        
    const [cartItems, setCartItems] = useState([]);
    const [isCartVisible, setCartVisible] = useState(false);
    
    const value ={
        cartItems,
        setCartItems,
        isCartVisible,
        setCartVisible,
    }

    return(
        <CartContext.Provider value= {value}>
            {children}
        </CartContext.Provider>
    );
}

const useCartContext = () => useContext(CartContext);

export {CartProvider, useCartContext};