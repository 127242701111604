import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";

import { MdWaterDrop } from "react-icons/md";
import { TbVaccine } from "react-icons/tb";

function SaibaMais() {
  const [Icon1isHover, setIcon1IsHover] = useState(false);
  const [Icon2isHover, setIcon2IsHover] = useState(false);

  const handleMouseEnterIcon1 = () => {
    setIcon1IsHover(true);
  };
  const handleMouseLeaveIcon1 = () => {
    setIcon1IsHover(false);
  };
  const handleMouseEnterIcon2 = () => {
    setIcon2IsHover(true);
  };
  const handleMouseLeaveIcon2 = () => {
    setIcon2IsHover(false);
  };
  const icon1Style = {
    color: Icon1isHover ? "#fff" : "#7e618a",
    fontSize: "30px",
  };
  const icon2Style = {
    color: Icon2isHover ? "#fff" : "#7e618a",
    fontSize: "30px",
  };

  return (
    <>
      <section
        id="features"
        className="section"
        data-stellar-background-ratio="0.2"
      >
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">Saiba Mais</h2>
            <hr className="lines" />
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12">
              <div className="container">
                <div className="row">
                  <div
                    className="col-lg-5 col-sm-6 col-xs-12 box-item"
                    onMouseEnter={handleMouseEnterIcon1}
                    onMouseLeave={handleMouseLeaveIcon1}
                  >
                    <Link to="/sobre" style={{ textDecoration: "none" }}>
                      <span className="icon">
                        <MdWaterDrop style={icon1Style} />
                      </span>
                      <div className="text">
                        <h4>Quem Somos</h4>
                        <p>
                          Dispomos de profissionais qualificados, todos os
                          equipamentos necessários, área física adequada, além
                          de protocolos e normativas compatíveis com as
                          rigorosas exigências.
                          <br />
                          Clique aqui e saiba mais!
                        </p>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-2 col-sm-6 col-xs-12 box-item"></div>
                  <div
                    className="col-lg-5 col-sm-6 col-xs-12 box-item"
                    onMouseEnter={handleMouseEnterIcon2}
                    onMouseLeave={handleMouseLeaveIcon2}
                  >
                    <Link to="/calendario" style={{ textDecoration: "none" }}>
                      <span className="icon">
                        <TbVaccine style={icon2Style} />
                      </span>
                      <div className="text">
                        <h4>Calendário de Vacinas</h4>
                        <p>
                          Vejas as vacinas para cada fase da vida e proteja sua
                          família!
                          <br />
                          Clique aqui e saiba mais!
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- Features Section End -->*/}
    </>
  );
}

export default SaibaMais;
