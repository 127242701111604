import React from "react";

import HeaderSite from "../../components/layout/HeaderSite";
import Contact from "../../components/layout/Contact";
import Unidades from "../../components/layout/Unidades";
import Footer from "../../components/layout/Footer";
import GoToTop from "../../components/layout/GoToTop";

import "../../main.css";

import imgSobre1 from "../../imagens/img/fotos/fotos/clinica/unidades/unidadePassos.jpg";
import imgSobre2 from "../../imagens/img/fotos/fotos/clinica/clinica2.jpg";
import imgSobre3 from "../../imagens/img/fotos/fotos/clinica/clinica3.jpg";

const Sobre = () => {
  return (
    <>
      <HeaderSite></HeaderSite>
      <section id="saibamais" className="section saibamais-section">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">Quem Somos</h2>
            <hr className="lines" />
            <div className="grid">
              <p>
                Dispomos de todos os equipamentos necessários, área física
                adequada, além de protocolos e normativas compatíveis com as
                rigorosas exigências para o manuseio e administração de vacinas,
                e temos como um de nossos objetivos a segurança plena do nosso
                cliente.
              </p>
              <img src={imgSobre1} alt="unidadePassos"></img>
              <img src={imgSobre3} alt="clinicaPrevimune"></img>
              <p>
                Em associação com os principais laboratórios e fornecedores,
                possuímos rede de frios de última geração, com controle
                automatizado para conservação de imunobiológicos, assim como
                habilitação e treinamento para a devida intervenção sob
                quaisquer condições.
                <br />
                Vacina é saúde, segurança e proteção.
              </p>
              <p>
                Buscamos não só a correta aplicação de vacinas, como também a
                perfeita sincronia com os calendários e recomendações da
                Sociedade Brasileira de Pediatria (SBP) e da Sociedade
                Brasileira de Pediatria Imunizações (SBIM), além de precisa
                interação com o Programa Nacional de Imunização (PNI), com o
                objetivo da mais ampla cobertura vacinal em prol do nosso
                cliente. Com espaço e equipe preparada para a excelência na área
                de vacinação, temos como meta estabelecer importante referência
                na indicação e administração de vacinas em crianças,
                adolescentes, adultos, gestantes e idosos na cidade de Passos -
                MG e região.
              </p>
              <img src={imgSobre2} alt="VacinaClinicaPrevimune"></img>
            </div>
          </div>
        </div>
      </section>
      <Unidades />
      <Contact />
      <Footer />
      <GoToTop />
    </>
  );
};

export default Sobre;
