import React from 'react';
import './styles.css';

export const ListItens2 = ({
  titulogeral,
  nomeColuna1,
  nomeColuna2,
  nomeColuna3,
  dadosGrid,
  tipo,
}) => {

  return (
    <div
      className="row"
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        paddingLeft: '2%',
        paddingRight: '2%',
        paddingBottom: '3%',
      }}
    >
      <div className="col-sm-3" />
      <div className="col-sm-9" style={{ padding: '1em' }}>
        <div id="grid_produtos">
          <br />
          <legend>{titulogeral}</legend>
          <br />
          <br />
          <table>
            <thead>
              <tr>
                <th colSpan={1} style={{ width: '15%' }}>
                  {nomeColuna1}
                </th>
                <th colSpan={1} style={{ width: '40%' }}>
                  {nomeColuna2}
                </th>
                <th colSpan={1} style={{ width: '20%' }}>
                  {nomeColuna3}
                </th>
              </tr>
            </thead>
            <tbody>
              {dadosGrid.map((dados) => (
                <tr>
                  <td style={{ textAlign: 'center' }}>{dados.id}</td>
                  <td style={{ textAlign: 'center' }}>{dados.coluna2}</td>
                  <td style={{ textAlign: 'center' }}>{dados.coluna3}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
