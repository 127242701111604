import "../../main.css";

import React, { Component } from "react";

import { AiOutlineArrowUp } from "react-icons/ai";
//import { IconContext } from 'react-icons';
//import { FaCog } from 'react-icons/fa';
//import Icon from './Icon';

const iconStyle = {
  display: "block",
  width: "36px",
  height: "36px",
  lineHeight: "36px",
  color: "#fff",
  fontSize: "10px",
  textAlign: "center",
  borderRadius: "4px",
  backgroundColor: "#E76114",
  WebkitTransition: "all 0.2s linear",
  MozTransition: "all 0.2s linear",
  OTransition: "all 0.2s linear",
  transition: "all 0.2s linear",
};

/*const icon = () => {
    return(
        <IconContext.Provider value={{style: {fontSize:'25px'}}}>
            <div>
                <AiOutlineArrowUp/>
            </div>
        </IconContext.Provider> 
    )
}*/

class ArrowUp extends Component {
  render() {
    return (
      <div>
        <AiOutlineArrowUp style={iconStyle}></AiOutlineArrowUp>
      </div>
    );
  }
}

function GoToTop() {
  return (
    <a href="#" className="back-to-top" style={{ display: "inline" }}>
      {/*
            <Icon                
                icon= {FaCog}
                size='380'
                color='#fff'
            />*/}
      <ArrowUp />
    </a>
  );
}

export default GoToTop;
