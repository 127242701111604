import React, { useState } from 'react';
import './CheckboxList.css'; // Importe o arquivo CSS para estilos
import { useListContext } from '../../../context/ListContext.jsx';

const CheckboxList = ({ options, onCheckboxChange, titulo }) => {
  const { selectedOptions, setSelectedOptions } = useListContext();

  const handleCheckboxChange = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== id));
    } else {
      setSelectedOptions([...selectedOptions, id]);
    }

    onCheckboxChange(selectedOptions);
  };

  const handleSelectAll = () => {
    const allIds = options.map((option) => option.id);
    if (selectedOptions.length === allIds.length) {
      setSelectedOptions([]);
    } else {
      setSelectedOptions(allIds);
    }
  };

  return (
    <div id="checkboxlist-princ">
      <p>{titulo}</p>
      <div className="checkbox-list-container">
        <div className="select-all">
          <input
            type="checkbox"
            id="select-all-checkbox"
            checked={selectedOptions.length === options.length}
            onChange={handleSelectAll}
          />
          <label htmlFor="select-all-checkbox">TODAS OPÇÕES</label>
        </div>
        <div className="checkbox-list">
          {options.map((option) => (
            <div key={option.id} className="checkbox-item">
              <input
                type="checkbox"
                id={`checkbox-${option.id}`}
                value={option.titulo}
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleCheckboxChange(option.id)}
              />
              <label htmlFor={`checkbox-${option.id}`}>{option.titulo}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheckboxList;
