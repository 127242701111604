import React from 'react';
import '../../Pages/site.css';
import '../../main.css';
import './styles.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
//import imgLogo from '../../imagens/logo.png';
import imgLogo from '../../imagens/img/logos/logo_original.png';

import { useAuthContext } from '../../context/AuthContext';
import { useNavigate, Link } from 'react-router-dom';

function HeaderSite() {
  const { logout, hasPermissionMenu } = useAuthContext();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  //console.log('Usr logado:', userLogin);
  return (
    <div className="col-sm-3" style={{ padding: '1em' }}>
      <ul id="nav" className="list-group">
        <div id="icone">
          <img src={imgLogo} alt="logo"></img>
          <br />
          <br />
          <i
            id="menuapp"
            className="bi bi-list"
            style={{ color: 'black', paddingLeft: '20%', fontSize: 'x-large' }}
          />
        </div>
        <div id="listasmenu">
          {hasPermissionMenu('menu') ? (
            <li className="list-group-item">
              <Link to="/Menu">
                <i
                  className="bi bi-house-door"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Home
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('cadProdutos') ? (
            <li className="list-group-item ">
              <Link to="/CadProdutos">
                <i
                  className="bi bi-bag-fill"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Produtos
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('cadFiliais') ? (
            <li className="list-group-item">
              <Link to="/CadFiliais">
                <i
                  className="bi bi-shop"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                {/* Filiais */}
                Franqueados
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('cadCargos') ? (
            <li className="list-group-item">
              <Link to="/CadCargos">
                <i
                  className="bi bi-bag-fill"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Cargos
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('cadUsuarios') ? (
            <li className="list-group-item">
              <Link to="/CadUsuarios">
                <i
                  className="bi bi-person-circle"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Usuários
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('cadVideos') ? (
            <li className="list-group-item">
              <Link to="/CadVideos">
                <i
                  className="bi bi-camera-reels"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                {/* Cadastro de Videos */}
                Cadastro de Materiais
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('videos') ? (
            <li className="list-group-item">
              <Link to="/Videos">
                <i
                  className="bi bi-camera-reels"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Videos
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('pedidosEstoque') ? (
            <li className="list-group-item">
              <Link to="/pedidosEstoque">
                <i
                  className="bi bi-bag-fill"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Estoque/Pedidos
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('cadAtivos') ? (
            <li className="list-group-item">
              <Link to="/cadAtivos">
                <i
                  className="bi bi-file-earmark-plus"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Cadastro de Ativos
              </Link>
            </li>
          ) : null}
          {hasPermissionMenu('lancAtivos') ? (
            <li className="list-group-item">
              <Link to="/lancAtivos">
                <i
                  className="bi bi-file-earmark"
                  style={{ color: 'black', paddingRight: '10px' }}
                />
                Lançamento de Ativos
              </Link>
            </li>
          ) : null}
          <li className="list-group-item">
            <button
              onClick={handleLogout}
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <i
                className="bi bi-x-circle"
                style={{ color: 'black', paddingRight: '10px' }}
              />
              Sair
            </button>
          </li>
        </div>
      </ul>
    </div>
  );
}

export default HeaderSite;
