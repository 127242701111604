import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../services/api';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';
import { ListItens } from '../../components/layout/ListItens';
import { useListContext } from '../../context/ListContext.jsx';
import CheckboxList from '../../components/layout/checkboxList';
import sweetAlert from '../../components/layout/sweetAlert.js';

const CadVideos = () => {
  const [idvideo, setidvideo] = useState('');
  const [titulovideo, settitulovideo] = useState('');
  const [descricaovideo, setdescricaovideo] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [linkvideo, setlinkvideo] = useState('');
  const [dadosGridVideo, setdadosGridVideo] = useState([]);
  const [dataPermissao, setdataPermissao] = useState([]);

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const { Opreracao, setOpreracao, selectedOptions, setSelectedOptions } =
    useListContext();

  const fileInputRef = useRef(null);

  const handleCheckboxChange = (selectedOptions) => {
    //console.log('Opções selecionadas:', selectedOptions);
  };

  useEffect(function () {
    carregaGridVideos();
    carregaListFiliais();
  }, []);

  useEffect(
    function () {
      VerificaOperacao();
    },
    [Opreracao]
  );

  async function VerificaOperacao() {
    let ok;
    let dados = Opreracao;

    if (dados !== '') {
      ok = await CarregaDados(dados);
      setOpreracao('');
    }
  }

  async function CarregaDados(Objeto) {
    let retorno;

    retorno = await BuscaVideoID(Objeto.Id);

    setidvideo(retorno[0].IDvideos);
    settitulovideo(retorno[0].titulo);
    setdescricaovideo(retorno[0].descricao);
    setlinkvideo(retorno[0].link);

    let dados = [];
    retorno = await BuscaDadosPermissaoAcesso(Objeto.Id);
    retorno.map(function (l) {
      dados.push(l.IDfilial);
    });
    setSelectedOptions(dados);

    if (Objeto.Operacao === 'Deletar') {
      setTextBotao('Deletar');
      setTipoBotao('btn btn-danger');
    } else {
      setTextBotao('Alterar');
      setTipoBotao('btn btn-secondary');
    }
  }

  async function BuscaDadosPermissaoAcesso(IDvideos) {
    return await api
      .get('/VideosFilialIDVideo/' + IDvideos)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaVideoID(Id) {
    return await api
      .get('/VideosID/' + Id)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeleteVideos() {
    return await api
      .delete('/Videos/' + idvideo)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaVideos() {
    return await api
      .get('/Videos')
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaFiliais() {
    return await api
      .get('/Filiais')
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function AtualizaPermissoes(IDvideos) {
    let retorno;

    retorno = await DeletaPermissoes(IDvideos);
    if (retorno.Delete === 'OK') {
      selectedOptions.map(function (id_) {
        InsertPermissoes(IDvideos, id_);
      });
      return { Permissoes: 'OK' };
    } else {
      return { Error: 'Atualização não realizada(DELETE)' };
    }
  }

  async function InsertPermissoes(IDvideos, IDfilial) {
    let dadosPost = {
      IDvideos: IDvideos,
      IDfilial: IDfilial,
    };

    return await api
      .post('/VideosFilial', dadosPost)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeletaPermissoes(IDvideos) {
    console.log('Deletando permissões de acesso:', IDvideos);
    return await api
      .delete('/VideosFilialIDVideo/' + IDvideos)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvaAlteraVideo() {
    let retorno;
    if (TextBotao === 'Deletar') {
      if (
        window.confirm(
          'Essa opção vai DELETAR definitivamente esse cadastro. \n\nClique OK para Prosseguir.'
        )
      ) {
        retorno = await DeleteVideos();
        if (retorno.Delete === 'OK') {
          await DeletaPermissoes(idvideo);
        }
        carregaGridVideos();
        LimparTela();
      }
    } else {
      if (selectedOptions.length > 0) {
        if (idvideo === '') {
          retorno = await InserirVideo();
          if (retorno.data.insert === 'OK') {
            await AtualizaPermissoes(retorno.data.IDvideos);
          }
          carregaGridVideos();
          LimparTela();
        } else {
          retorno = await AlterarVideo();
          if (retorno.data.update === 'OK') {
            await AtualizaPermissoes(idvideo);
          }
          carregaGridVideos();
          LimparTela();
        }
      } else {
        if (idvideo === '') {
          sweetAlert(
            'warning',
            'Atenção',
            'Favor preencher quais franquias terão acesso ao material!'
          );
        } else {
          await DeletaPermissoes(idvideo);
          carregaGridVideos();
          LimparTela();
        }
      }
    }
  }

  async function InserirVideo() {
    let formData = new FormData();

    formData.append('titulo', titulovideo);
    formData.append('descricao', descricaovideo);
    formData.append('link', linkvideo);
    formData.append('imgvideo', imageFile);

    try {
      const response = await api.post('/Videos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Importante definir o header
        },
      });

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function AlterarVideo() {
    let formData = new FormData();

    formData.append('IDvideos', idvideo);
    formData.append('titulo', titulovideo);
    formData.append('descricao', descricaovideo);
    formData.append('link', linkvideo);
    if (!!imageFile) {
      formData.append('imgvideo', imageFile);
    }

    try {
      const response = await api.put('/VideosAlterar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function carregaListFiliais() {
    let Filiais = [];

    let retorno = await BuscaFiliais();
    retorno.forEach((element) => {
      Filiais.push({
        id: element.IDfilial,
        titulo: element.nome + ' - ' + element.cidade,
      });
    });

    setdataPermissao(Filiais);
  }

  async function carregaGridVideos() {
    let videos = [];

    let retorno = await BuscaVideos();
    retorno.forEach((element) => {
      videos.push({
        id: element.IDvideos,
        coluna2: element.titulo,
        coluna3:
          element.descricao.length > 15
            ? element.descricao.substring(0, 15) + '...'
            : element.descricao,
      });
    });

    setdadosGridVideo(videos);
  }

  function LimparTela() {
    setidvideo('');
    settitulovideo('');
    setdescricaovideo('');
    setlinkvideo('');
    setTextBotao('Salvar');
    setTipoBotao('btn btn-success');
    setSelectedOptions([]);
    setImageFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        className="row"
        style={{
          padding: '2%',
          paddingBottom: '0',
          backgroundColor: 'rgb(243, 240, 231)',
        }}
      >
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div id="formulario">
            <form id="formulario" autoComplete="off">
              <fieldset>
                <legend>CADASTRO - MATERIAIS</legend>
                <input
                  id="idproduto"
                  style={{ display: 'none' }}
                  type="text"
                  value={idvideo}
                  onChange={(e) => setidvideo(e.target.value)}
                />
                <br />
                <br />
                <label>Titulo:</label>
                <br />
                <input
                  id="nomeproduto"
                  className="width_cemporcento  form-control"
                  type="text"
                  value={titulovideo}
                  onChange={(e) => settitulovideo(e.target.value)}
                />
                <br />
                <br />
                <label>Descrição:</label>
                <br />
                <input
                  id="descricaoproduto"
                  className="width_cemporcento form-control"
                  type="text"
                  maxLength={80}
                  value={descricaovideo}
                  onChange={(e) => setdescricaovideo(e.target.value)}
                />
                <br />
                <br />

                <div className="row">
                  <div className="col-sm-6">
                    <label>Link do Material:</label>
                    <br />
                    <input
                      id="linkVideo"
                      className="width_cemporcento form-control"
                      type="text"
                      value={linkvideo}
                      onChange={(e) => setlinkvideo(e.target.value)}
                    />
                    <br />
                    <br />
                    <label>Imagem:</label>
                    <br />
                    <input
                      id="imgvideo"
                      className="width_cemporcento form-control"
                      type="file"
                      accept="image/*"
                      ref={fileInputRef}
                      onChange={(e) => setImageFile(e.target.files[0])}
                    />
                  </div>

                  <div className="col-sm-6" style={{ paddingLeft: '50px' }}>
                    <CheckboxList
                      options={dataPermissao}
                      onCheckboxChange={handleCheckboxChange}
                      titulo={'ACESSO AO VIDEO'}
                    />
                  </div>
                </div>

                <br />
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao}
                      type="button"
                      value={TextBotao}
                      onClick={() => SalvaAlteraVideo()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      onClick={() => LimparTela()}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <ListItens
        titulogeral={'MATERIAIS CADASTRADOS'}
        nomeColuna1={'ID'}
        nomeColuna2={'TITULO'}
        nomeColuna3={'DESCRIÇÃO'}
        dadosGrid={dadosGridVideo}
        tipo={'2'}
      />
    </div>
  );
};

export default CadVideos;
