import React, { useState, useEffect } from 'react';
import HeaderSite from '../../components/layout/HeaderMenu';
import CheckboxList from '../../components/layout/checkboxList2';
import BarChart from '../../components/layout/barChart';
import { api } from '../../services/api.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BuscaToken } from '../../api/consultaApi';
import { useListContext } from '../../context/ListContext.jsx';
import { useAuthContext } from '../../context/AuthContext.jsx';
import { ListItens2 } from '../../components/layout/ListItnes2.js';
import sweetAlert from '../../components/layout/sweetAlert.js';
import './styles.css';

const EstoquePedido = () => {
  const [dataProdutos, setdataProdutos] = useState([]);
  const [titulotela, settitulotela] = useState('');
  const [produtoselecionado_titulo, setprodutoselecionado_titulo] = useState(
    'PRODUTO SELECIONADO'
  );
  const [produtoselecionado_id, setprodutoselecionado_id] = useState(0);
  const [mediadiaria, setmediadiaria] = useState('0');
  const [numpedidos, setnumpedidos] = useState('0');
  const [estoquemin, setestoquemin] = useState(0);
  const [estoquemax, setestoquemax] = useState(0);
  const [estoque, setestoque] = useState(0);

  const [mesatual, setmesatual] = useState('0000/00');

  const [datalancamento, setdatalancamento] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [txtvendadiaria, settxtvendadiaria] = useState(0);
  const [txtestoque, settxtestoque] = useState(0);

  const [dadosGrid, setdadosGrid] = useState([]);
  const [tipobotao, settipobotao] = useState('btn btn-success');
  const [textobotao, settextobotao] = useState('Salvar');

  const { selectedOptions, setSelectedOptions } = useListContext();
  const { payload } = useAuthContext();

  const IDusuario = payload.user.IDusuario;
  const IDfilial = payload.user.IDfilial;

  useEffect(function () {
    console.log('IDfilial', IDfilial);
    carregaListProdutos(IDfilial);
    BuscaMes(IDfilial);
    setSelectedOptions([]);
  }, []);

  useEffect(
    function () {
      VerificaOperacao(IDfilial);
    },
    [selectedOptions]
  );

  async function carregaLancamentos(IDfilial, IDproduto, anomes) {
    var lanc = [];

    let retor = await getLancamentos(IDfilial, IDproduto, anomes);
    if (Array.isArray(retor)) {
      retor.forEach((element) => {
        lanc.push({
          id: element.data,
          coluna2: element.qtdVenda,
          coluna3: element.qtdEstoque,
        });
      });
    }

    setdadosGrid(lanc);
  }

  async function getLancamentos(IDfilial, IDproduto, anomes) {
    return await api
      .get('/LancMes/' + IDfilial + '/' + IDproduto + '/' + anomes)
      .then(function (response) {
        console.log(response);
        return response.data[0];
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  const handleCheckboxChange = (selectedOptions) => {
    //console.log('Opções selecionadas:', selectedOptions);
  };

  async function VerificaOperacao(IDfilial) {
    let ok;
    let dados = selectedOptions;

    if (dados != '') {
      console.log('dados', dados[0]);
      ok = await CarregaDados(IDfilial, dados[0], mesatual);
    } else {
      await limpatela();
    }
  }

  async function BuscaMes(IDfilial) {
    // try {
    //   await BuscaToken();
    // } catch (error) {
    //   console.log('Erro ao consultar token', error);
    // }

    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    let retorno = await BuscaUltimoRegistroVendaDiaria(IDfilial);

    if (retorno.length > 0) {
      retorno.forEach((element) => {
        console.log('restorno', element.dataVenda);
        const dataAtual = new Date(element.dataVenda);
        const mes = meses[dataAtual.getMonth()];
        const ano = dataAtual.getFullYear();

        if (!!element.dataVenda) {
          setmesatual(element.dataVenda.slice(0, 7));
        }
        settitulotela(`${mes} de ${ano}`);
      });
    } else {
      const dataAtual = new Date();
      const mes = meses[dataAtual.getMonth()];
      const ano = dataAtual.getFullYear();

      settitulotela(`${mes} de ${ano}`);
    }
  }

  async function BuscaUltimoRegistroVendaDiaria(IDfilial) {
    return await api
      .get('/VendaDiariaUltimo/' + IDfilial)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function AnteriorMes() {
    var mesAtual_ = mesatual;
    const partes = mesAtual_.split('-');
    let ano = parseInt(partes[0]);
    let mes = parseInt(partes[1]);
    // Adiciona 1 ao mês
    mes = mes - 1;

    // Verifica se o mês ultrapassou 12, se sim, incrementa o ano e define o mês como 1
    if (mes == 0) {
      mes = 12;
      ano = ano - 1;
    }

    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    // Formata o resultado para 'yyyy-MM'
    setmesatual(`${ano}-${mes.toString().padStart(2, '0')}`);

    settitulotela(`${meses[mes - 1]} de ${ano}`);
    if (produtoselecionado_id != 0) {
      // carregaLancamentos(
      //   IDfilial,
      //   produtoselecionado_id,
      //   `${ano}-${mes.toString().padStart(2, '0')}`
      // );

      await CarregaDados(
        IDfilial,
        produtoselecionado_id,
        `${ano}-${mes.toString().padStart(2, '0')}`
      );
    }
  }

  async function ProximoMes() {
    var mesAtual_ = mesatual;
    const partes = mesAtual_.split('-');
    let ano = parseInt(partes[0]);
    let mes = parseInt(partes[1]);

    // Adiciona 1 ao mês
    mes++;

    // Verifica se o mês ultrapassou 12, se sim, incrementa o ano e define o mês como 1
    if (mes > 12) {
      mes = 1;
      ano++;
    }

    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    // Formata o resultado para 'yyyy-MM'
    await setmesatual(`${ano}-${mes.toString().padStart(2, '0')}`);

    settitulotela(`${meses[mes - 1]} de ${ano}`);
    if (produtoselecionado_id != 0) {
      //carregaLancamentos(
      // IDfilial,
      // produtoselecionado_id,
      // `${ano}-${mes.toString().padStart(2, '0')}`
      //);

      await CarregaDados(
        IDfilial,
        produtoselecionado_id,
        `${ano}-${mes.toString().padStart(2, '0')}`
      );
    }
  }

  async function CarregaDados(IDfilial, IDprodutos, anoMes) {
    let retorno = await BuscaProdutosID(IDfilial, IDprodutos);
    if (retorno.length > 0) {
      setprodutoselecionado_titulo(retorno[0].nome);
      setprodutoselecionado_id(IDprodutos);
      setdatalancamento(new Date().toISOString().substr(0, 10));

      await carregaLancamentos(IDfilial, IDprodutos, anoMes);

      let retorno2 = await BuscaMediaProduto(
        IDfilial,
        IDprodutos,
        retorno[0].tempoReposicao,
        retorno[0].loteMinimoReposicao,
        anoMes
      );
      console.log('Busca 2 Media', retorno2);
      if (Array.isArray(retorno2)) {
        retorno2.forEach((element) => {
          setmediadiaria(element.media);
          setestoquemin(element.estoqueMin);
          setestoquemax(element.estoqueMax);

          retorno = BuscaEstoque(IDfilial, IDprodutos);
          if (retorno.length > 0) {
            setestoque(retorno[0].qtdEstoque);

            let pedido = element.media * 25 - retorno[0].qtdEstoque;
            pedido = arredondarParaInteiro(pedido);
            setnumpedidos(pedido);
          } else {
            setestoque(0);

            let pedido = element.media * 25 - 0;
            pedido = arredondarParaInteiro(pedido);
            setnumpedidos(pedido);
          }
        });
      }

      buscadata(datalancamento);
    }
  }

  function arredondarParaInteiro(numero) {
    return Math.round(numero);
  }

  async function BuscaMediaProduto(
    IDfilial,
    IDprodutos,
    temporeposicao,
    loteminreposicao,
    anoMes
  ) {
    return await api
      .get(
        '/MediaDiaria/' +
          IDfilial +
          '/' +
          IDprodutos +
          '/' +
          anoMes +
          '/' +
          temporeposicao +
          '/' +
          loteminreposicao
      )
      .then(function (response) {
        console.log('then');
        console.log(response.data[0]);
        return response.data[0];
      })
      .catch(function (error) {
        console.log('catch');
        console.log(error);
        return error;
      });
  }

  async function AlterarEstoque(IDfilial, IDusuario) {
    const dados = {
      IDproduto: produtoselecionado_id,
      qtdEstoque: txtestoque,
      dataLancamento: datalancamento,
      IDfilial: IDfilial,
      IDusuario: IDusuario,
    };

    try {
      return await api
        .put('/Estoque', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function AlterarvendaDiaria(IDfilial, IDusuario) {
    const dados = {
      IDproduto: produtoselecionado_id,
      qtdVenda: txtvendadiaria,
      dataVenda: datalancamento,
      IDfilial: IDfilial,
      IDusuario: IDusuario,
    };

    try {
      return await api
        .put('/VendaDiaria', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function carregaListProdutos(IDfilial) {
    let Produtos = [];

    // try {
    //   await BuscaToken();
    // } catch (error) {
    //   console.log('Erro ao consultar token', error);
    // }

    let retorno = await BuscaProdutos(IDfilial);
    retorno.forEach((element) => {
      Produtos.push({
        id: element.IDprodutos,
        titulo: element.nome,
      });
    });
    setdataProdutos(Produtos);
  }

  async function BuscaProdutos(IDfilial) {
    return await api
      .get('/Produtos/' + IDfilial)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaEstoque(IDfilial, IDproduto) {
    return await api
      .get('/EstoqueAtual/' + IDfilial + '/' + IDproduto)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaProdutosID(IDfilial, IDprodutos) {
    return await api
      .get('/ProdutosID/' + IDfilial + '/' + IDprodutos)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
      return false;
    }
    const date = new Date(dateString);
    const isValid = !isNaN(date.getTime());
    return isValid;
  }

  async function buscadata(dataInput) {
    console.log('data', dataInput);
    if (produtoselecionado_id != 0) {
      let IsdataValida = isValidDate(dataInput);
      if (IsdataValida) {
        let okEstoque = await getEstoque(
          IDfilial,
          produtoselecionado_id,
          dataInput
        );
        let okVendaDiaria = await getVenda(
          IDfilial,
          produtoselecionado_id,
          dataInput
        );

        if (okEstoque.length > 0) {
          settxtestoque(okEstoque[0].qtdEstoque);
          settxtvendadiaria(okVendaDiaria[0].qtdVenda);
          settipobotao('btn btn-secondary');
          settextobotao('Alterar');
        }
      }
    }
  }

  async function getEstoque(IDfilial, IDproduto, datalancamento) {
    console.log('buscaEstoque', IDfilial, IDproduto, datalancamento);
    return await api
      .get('/EstoqueData/' + IDfilial + '/' + IDproduto + '/' + datalancamento)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function getVenda(IDfilial, IDproduto, datavenda) {
    return await api
      .get('/VendaDiariaData/' + IDfilial + '/' + IDproduto + '/' + datavenda)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function InserirEstoque(IDfilial, IDusuario) {
    const dados = {
      IDproduto: produtoselecionado_id,
      qtdEstoque: txtestoque,
      dataLancamento: datalancamento,
      IDfilial: IDfilial,
      IDusuario: IDusuario,
    };

    try {
      return await api
        .post('/Estoque', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function InserirvendaDiaria(IDfilial, IDusuario) {
    const dados = {
      IDproduto: produtoselecionado_id,
      qtdVenda: txtvendadiaria,
      dataVenda: datalancamento,
      IDfilial: IDfilial,
      IDusuario: IDusuario,
    };

    try {
      return await api
        .post('/VendaDiaria', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function limpatela() {
    settxtestoque('0');
    settxtvendadiaria('0');
    setmediadiaria(0);
    setestoquemin(0);
    setestoquemax(0);
    setmediadiaria(0);
    setnumpedidos(0);
    settipobotao('btn btn-success');
    settextobotao('Salvar');
    setdatalancamento(new Date().toISOString().substr(0, 10));
    setprodutoselecionado_titulo('');
    setprodutoselecionado_id(0);
  }

  async function salvarLancamento() {
    let IsdataValida = isValidDate(datalancamento);

    if (IsdataValida && txtestoque > -1 && txtvendadiaria > -1) {
      let okEstoque;
      let okVendaDiaria;

      if (tipobotao != 'btn btn-success') {
        okEstoque = await AlterarEstoque(IDfilial, IDusuario);
        okVendaDiaria = await AlterarvendaDiaria(IDfilial, IDusuario);
      } else {
        okEstoque = await InserirEstoque(IDfilial, IDusuario);
        okVendaDiaria = await InserirvendaDiaria(IDfilial, IDusuario);
      }

      if (okEstoque && okVendaDiaria) {
        //alert('Salvo com sucesso!');
        sweetAlert('success', 'Salvo com sucesso!', '');
        settxtestoque('0');
        settxtvendadiaria('0');
        settipobotao('btn btn-success');
        settextobotao('Salvar');
        setdatalancamento(new Date().toISOString().substr(0, 10));

        await carregaLancamentos(IDfilial, produtoselecionado_id, mesatual);

        await CarregaDados(IDfilial, produtoselecionado_id, mesatual);
      }
    } else {
      //alert('Preencha todos os campos!');
      sweetAlert('warning', 'Atenção', 'Preencha todos os campos!');
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        padding: '2%',
        width: '100%',
        height: '100vh',
      }}
    >
      <div className="row" style={{ backgroundColor: 'rgb(243, 240, 231)' }}>
        <HeaderSite></HeaderSite>

        <div
          className="col-sm-9"
          style={{ padding: '1em', backgroundColor: 'white' }}
        >
          <div className="row">
            <div className="col-sm-10">
              <p id="titulopage"> {titulotela}</p>
              <p
                style={{ fontSize: '12px', width: '100%', textAlign: 'center' }}
              >
                {' '}
                (Dias Trabalhados: 25){' '}
              </p>
            </div>

            <div className="col-sm-2" style={{ alignItems: 'rigth' }}>
              <input
                className="btn btn-info"
                type="button"
                value="<"
                onClick={() => AnteriorMes()}
              />
              <input
                className="btn btn-info"
                type="button"
                style={{ marginLeft: '5px' }}
                value=">"
                onClick={() => ProximoMes()}
              />
            </div>
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-sm-1"></div>
            <div className="col-sm-6" style={{ alignItems: 'center' }}>
              <CheckboxList
                options={dataProdutos}
                onCheckboxChange={handleCheckboxChange}
                titulo={'PRODUTOS'}
              />
            </div>
          </div>

          <br />

          <div id="analises">
            <p>{produtoselecionado_titulo}</p>

            <div className="row">
              <div className="col-sm-1"></div>

              <div className="col-sm-3" id="mediadiaria">
                <h3>Média</h3>
                <h1>{mediadiaria}</h1>
                <h5>itens por dia</h5>
              </div>

              <div className="col-sm-4" id="grafico">
                <BarChart min={estoquemin} current={estoque} max={estoquemax} />
              </div>

              <div className="col-sm-3" id="pedido">
                <h3>Pedidos</h3>
                <h1>{numpedidos}</h1>
                <h5>itens</h5>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />

          <div id="lancamentos">
            <p>Lançamentos</p>
            <div className="row">
              <div className="col-sm-1"></div>

              <div className="col-sm-3">
                <label>Data Lançamento:</label>
                <input
                  id="datalancamento"
                  className="width_cemporcento form-control form_lancamento"
                  type="date"
                  value={datalancamento}
                  style={{ textAlign: 'center' }}
                  onChange={(e) => setdatalancamento(e.target.value)}
                  onBlur={(e) => buscadata(e.target.value)}
                />
              </div>

              <div className="col-sm-3">
                <label>Venda Diaria:</label>
                <input
                  id="txtvendadiaria"
                  className="width_cemporcento form-control form_lancamento"
                  type="number"
                  value={txtvendadiaria}
                  min={0}
                  style={{ textAlign: 'center' }}
                  onChange={(e) =>
                    settxtvendadiaria(arredondarParaInteiro(e.target.value))
                  }
                />
              </div>

              <div className="col-sm-3">
                <label>Estoque Atual:</label>
                <input
                  id="txtestoque"
                  className="width_cemporcento form-control form_lancamento"
                  type="text"
                  value={txtestoque}
                  min={0}
                  style={{ textAlign: 'center' }}
                  onChange={(e) =>
                    settxtestoque(arredondarParaInteiro(e.target.value))
                  }
                />
              </div>

              <div className="col-sm-2">
                <br />
                <input
                  className={tipobotao}
                  type="button"
                  value={textobotao}
                  onClick={() => salvarLancamento()}
                />
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>

        <ListItens2
          titulogeral={'LANÇAMENTOS'}
          nomeColuna1={'DATA'}
          nomeColuna2={'VENDAS'}
          nomeColuna3={'ESTOQUE'}
          dadosGrid={dadosGrid}
          tipo={'0'}
        />
      </div>
    </div>
  );
};

export default EstoquePedido;
