import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import dayjs from 'dayjs';

const api = axios.create({
  //baseURL: "http://192.168.1.193:3001",
  //baseURL: 'http://localhost:3001',
  //baseURL: 'previmune.kinghost.net/ApiPrevimune',
  //Alterado domínio
  //baseURL: 'http://previmune.nodejsng10f04.kinghost.net',
  //baseURL: 'https://previmune.com.br:21147',
  baseURL: 'https://previmune.com.br/api/',
});

const setToken = (token) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const clearToken = () => {
  delete api.defaults.headers.common['Authorization'];
};

let authToken = localStorage.getItem('accessToken')
  ? localStorage.getItem('accessToken')
  : null;

const getToken = () => {
  const token = localStorage.getItem('accessToken');
  return token ? `Bearer ${token}` : '';
};

api.interceptors.request.use(async (req) => {
  if (!authToken) {
    authToken = localStorage.getItem('accessToken')
      ? localStorage.getItem('accessToken')
      : null;
  }

  req.headers.Authorization = getToken();

  //console.log('token pra ver Exp', authToken);

  if (authToken) {
    //console.log('token na requisição', authToken);
    // const tokenDecoded = jwtDecode(authToken);
    // console.log('tokenDecod', tokenDecoded.exp);
    // const isExpired = dayjs.unix(tokenDecoded.exp).diff(dayjs()) < 1;
    // console.log('isExpired:', isExpired);
    // if (!isExpired) {
    //   //NÃO ESTÁ EXPIRADO
    //   console.log('Não está Expirado', isExpired);
    //   return req;
    // } else {
    //   console.log('Expirou');
    // }
    // const response = await api.post('/refreshToken', {
    //   refreshToken: tokenDecoded.refreshToken,
    // });
    // console.log('refresh', response.data);
    //   localStorage.setItem('accessToken', JSON.stringify(response.data.token));
    //   getToken();
  }

  return req;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    console.log('ENTROU NO ERRO INTERCEPTOR');

    if (error.response) {
      if (error.response.status === 401) {
        console.log('ERRO 401');

        //Comentado para testar erro que estava ocorrendo na tela de videos
        //localStorage.removeItem('accessToken');

        return;
      }
    }

    // Parte necessária para retornar as requisições que não tiveram sucesso
    return Promise.reject(error);
  }
);

async function refresh(refreshToken) {
  console.log('ENTROU FUNÇÃO');
  return api.post('/refreshToken', {
    refreshToken: refreshToken,
  });
}

export { api, setToken, clearToken, refresh };
