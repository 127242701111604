import "../../main.css";

import React, { Component } from "react";

import {
  FaFacebookF,
  FaInstagram,
  FaMapMarkerAlt,
  FaPhoneAlt,
} from "react-icons/fa";

class FacebookIcon extends Component {
  render() {
    return (
      <div>
        <FaFacebookF></FaFacebookF>
      </div>
    );
  }
}
class InstagramIcon extends Component {
  render() {
    return (
      <div>
        <FaInstagram></FaInstagram>
      </div>
    );
  }
}
class MapIcon extends Component {
  render() {
    return (
      <div>
        <FaMapMarkerAlt></FaMapMarkerAlt>
      </div>
    );
  }
}
class PhoneIcon extends Component {
  render() {
    return (
      <div>
        <FaPhoneAlt></FaPhoneAlt>
      </div>
    );
  }
}

function Icons(props) {
  if (props.icon === "facebook") {
    return (
      //<Link to={!!props.link ? props.link : "#" } className="" style={{display:'inline'}}>
      <FacebookIcon />
      //</Link>
    );
  }
  if (props.icon === "instagram") {
    return <InstagramIcon />;
  }
  if (props.icon === "mapMarker") {
    return <MapIcon />;
  }
  if (props.icon === "phone") {
    return <PhoneIcon />;
  }
}

export default Icons;
