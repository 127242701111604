import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../services/api';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';
import { ListItens } from '../../components/layout/ListItens';
import { useListContext } from '../../context/ListContext.jsx';
import { useAuthContext } from '../../context/AuthContext';
import sweetAlert from '../../components/layout/sweetAlert.js';

import BuscaFiliaisApi from '../../api/buscaFiliaisApi';

const CadProdutos = () => {
  const [idproduto, setidproduto] = useState('');
  const [idfilial, setIDfilial] = useState(0);
  const [nomeproduto, setnomeproduto] = useState('');
  const [descricaoproduto, setdescricaoproduto] = useState('');
  const [valor, setvalor] = useState('');
  const [cidadeFilial, setCidadeFilial] = useState('');
  const [ufFilial, setUFFilial] = useState('');
  const [dadosGridProdutos, setdadosGridProdutos] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const [tempoReposicao, setTempoReposicao] = useState(0);
  const [loteMinimoReposicao, setLoteMinimoReposicao] = useState(0);

  const [filiais, setFiliais] = useState([]);
  const [selectedFilial, setSelectedFilial] = useState(-1);

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const { Opreracao, setOpreracao } = useListContext();
  const { userLogin, payload } = useAuthContext();

  const fileInputRef = useRef(null);
  const idFilialUser = payload.user.IDfilial;

  useEffect(function () {
    setIDfilial(idFilialUser);
    setCidadeFilial(payload.user.cidade);
    setUFFilial(payload.user.uf);

    if (filiais.length === 0) {
      carregaUnidades();
    }
    if (payload.user.IDusuario !== 1) {
      carregaGridProdutos();
    }
  }, []);

  useEffect(() => {
    if (payload.user.IDusuario !== 1 || selectedFilial !== -1) {
      carregaGridProdutos();
    } else {
      setdadosGridProdutos([]);
    }
  }, [selectedFilial]);

  useEffect(
    function () {
      VerificaOperacao();
    },
    [Opreracao]
  );

  async function VerificaOperacao() {
    let ok;
    let dados = Opreracao;
    if (dados !== '') {
      ok = await CarregaDados(dados);
      setOpreracao('');
    }
  }

  async function carregaUnidades() {
    const response = await BuscaFiliaisApi();

    if (!!response) {
      const filiaisOrdenadas = [...response].sort((a, b) =>
        a.cidade.localeCompare(b.cidade)
      );
      setFiliais(filiaisOrdenadas);
    }
  }

  async function CarregaDados(Objeto) {
    let retorno = await BuscaProdutoID(Objeto.Id);

    //console.log(retorno[0]);
    setidproduto(retorno[0].IDprodutos);
    setnomeproduto(retorno[0].nome);
    setdescricaoproduto(retorno[0].descricao);
    setvalor(DuasCasasDecimal(retorno[0].valor));
    setTempoReposicao(retorno[0].tempoReposicao);
    setLoteMinimoReposicao(retorno[0].loteMinimoReposicao);

    if (Objeto.Operacao === 'Deletar') {
      setTextBotao('Deletar');
      setTipoBotao('btn btn-danger');
    } else {
      setTextBotao('Alterar');
      setTipoBotao('btn btn-secondary');
    }
  }

  async function BuscaProdutoID(Id) {
    return await api
      //.get('/ProdutosID/1/' + Id) //ajustar IDfilial
      .get('/ProdutosID/' + idfilial + '/' + Id)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeleteProdutos() {
    return await api
      .delete('/Produtos/' + idproduto)
      .then(function (response) {
        console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaProdutos() {
    const idBuscaFilial = !!idfilial ? idfilial : idFilialUser;

    return await api
      .get('/Produtos/' + idBuscaFilial)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvaAlteraProduto() {
    if (TextBotao === 'Deletar') {
      if (
        window.confirm(
          'Essa opção vai DELETAR definitivamente esse cadastro. \n\nClique OK para Prosseguir.'
        )
      ) {
        await DeleteProdutos();
        carregaGridProdutos();
        LimparTela();
      }
    } else {
      let retorno;
      if (idproduto === '') {
        if (payload.user.IDusuario === 1 && selectedFilial === -1) {
          //alert('Preencha para qual filial é este produto.');
          sweetAlert(
            'warning',
            'Atenção',
            'Preencha para qual filial é este produto.'
          );
          return;
        }
        retorno = await InserirProduto();
        if (!!retorno.error) {
          //alert('Atenção! ' + retorno.error);
          sweetAlert('warning', retorno.error.toString());
          return;
        }
        carregaGridProdutos();
        LimparTela();
      } else {
        retorno = await AlterarProduto();
        carregaGridProdutos();
        LimparTela();
      }
    }
  }

  async function InserirProduto() {
    let formData = new FormData();

    formData.append('IDfilial', idfilial);
    formData.append('IDprodutos', idproduto);
    formData.append('nome', nomeproduto);
    formData.append('descricao', descricaoproduto);
    formData.append('valor', DuasCasasDecimal(valor));
    formData.append('tempoReposicao', tempoReposicao);
    formData.append('loteMinimoReposicao', loteMinimoReposicao);
    if (imageFile) {
      formData.append('imgproduto', imageFile);
    } else {
      formData.append('imgproduto', '');
    }

    try {
      const response = await api.post('/Produtos', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Importante definir o header
        },
      });
      return response.data.insert;
    } catch (error) {
      console.log('error', error.response.data);
      return error.response.data;
    }
  }

  function DuasCasasDecimal(valor) {
    var valor1 = valor;
    return (Math.round(valor1 * 100) / 100).toFixed(2);
  }
  function arredondarParaInteiro(numero) {
    return Math.round(numero.replace(/^0+/, ''));
  }

  async function AlterarProduto() {
    let formData = new FormData();

    formData.append('IDfilial', idfilial);
    formData.append('IDprodutos', idproduto);
    formData.append('nome', nomeproduto);
    formData.append('descricao', descricaoproduto);
    formData.append('valor', DuasCasasDecimal(valor));
    formData.append('tempoReposicao', tempoReposicao);
    formData.append('loteMinimoReposicao', loteMinimoReposicao);
    if (!!imageFile) {
      formData.append('imgproduto', imageFile); // Adicione o arquivo ao FormData
    }

    try {
      const response = await api.put('/ProdutosAlterar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Importante definir o header
        },
      });

      return response.data.update;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function carregaGridProdutos() {
    var produtos = [];

    let retorno = await BuscaProdutos();
    retorno.forEach((element) => {
      produtos.push({
        id: element.IDprodutos,
        coluna2: element.nome,
        coluna3: 'R$ ' + DuasCasasDecimal(element.valor),
      });
    });

    setdadosGridProdutos(produtos);
  }

  function LimparTela() {
    //console.log('Filial Logada: ', filialLogada);
    setidproduto('');
    setnomeproduto('');
    setdescricaoproduto('');
    setvalor('');
    setImageFile(null);
    //setSelectedFilial(-1);
    setCidadeFilial('');
    setUFFilial('');
    setTempoReposicao(0);
    setLoteMinimoReposicao(0);
    setTextBotao('Salvar');
    setTipoBotao('btn btn-success');
    // Limpeza do input de arquivo usando a ref
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const handleSelectChange = (event) => {
    const valorSelecionado = parseInt(event.target.value);
    setSelectedFilial(valorSelecionado);
    if (valorSelecionado > 0) setIDfilial(valorSelecionado);
  };

  function ExibeFilial() {
    if (payload.user.IDusuario !== 1) {
      return (
        <input
          id="filialproduto"
          className="width_cemporcento form-control form_lancamento"
          type="text"
          value={cidadeFilial + ' - ' + ufFilial}
          disabled={true}
        />
      );
    } else {
      return (
        <select
          onChange={(e) => handleSelectChange(e)}
          style={{
            borderRadius: '5px',
            width: '100%',
            height: '30px',
            padding: '2px',
          }}
          id="dropdown"
          value={selectedFilial}
        >
          <option key="-1" value="-1">
            -- SELECIONE UMA OPÇÃO --
          </option>
          {filiais.length > 0 &&
            filiais.map((filial) => (
              <option key={filial.IDfilial} value={filial.IDfilial}>
                {filial.cidade} - {filial.uf}
              </option>
            ))}
        </select>
      );
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        className="row"
        style={{
          padding: '2%',
          paddingBottom: '0',
          backgroundColor: 'rgb(243, 240, 231)',
        }}
      >
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div id="formulario">
            <form id="formulario" autoComplete="off">
              <fieldset>
                <legend>CADASTRO - PRODUTOS</legend>
                <input
                  id="idproduto"
                  style={{ display: 'none' }}
                  type="text"
                  value={idproduto}
                  onChange={(e) => setidproduto(e.target.value)}
                />
                <br />
                <br />
                <label>Franquia:</label>
                <br />
                <ExibeFilial></ExibeFilial>
                <br />
                <br />
                <label>Nome:</label>
                <br />
                <input
                  id="nomeproduto"
                  className="width_cemporcento  form-control"
                  type="text"
                  value={nomeproduto}
                  onChange={(e) => setnomeproduto(e.target.value)}
                />
                <br />
                <br />
                <label>Descrição:</label>
                <br />
                <input
                  id="descricaoproduto"
                  className="width_cemporcento form-control"
                  type="text"
                  value={descricaoproduto}
                  onChange={(e) => setdescricaoproduto(e.target.value)}
                />
                <br />
                <div className="row">
                  <div className="col-sm-6">
                    <label>Tempo de Reposição:</label>
                    <br />
                    <input
                      id="valorproduto"
                      className="width_cemporcento form-control"
                      type="number"
                      min={0}
                      value={tempoReposicao}
                      onChange={(e) =>
                        setTempoReposicao(arredondarParaInteiro(e.target.value))
                      }
                    />
                  </div>
                  <div className="col-sm-6">
                    <label>Lote Mínimo para Reposição:</label>
                    <br />
                    <input
                      id="valorproduto"
                      className="width_cemporcento form-control"
                      type="number"
                      min={0}
                      value={loteMinimoReposicao}
                      onChange={(e) =>
                        setLoteMinimoReposicao(
                          arredondarParaInteiro(e.target.value)
                        )
                      }
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-3">
                    <label>Valor:</label>
                    <br />
                    <input
                      id="valorproduto"
                      className="width_cemporcento form-control"
                      type="number"
                      min={0}
                      value={valor}
                      onChange={(e) => setvalor(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-9">
                    <label>Imagem:</label>
                    <br />
                    <input
                      id="imgproduto"
                      className="width_cemporcento form-control"
                      type="file"
                      accept="image/*"
                      ref={fileInputRef} // Atribui a ref ao input de arquivo
                      onChange={(e) => setImageFile(e.target.files[0])}
                    />
                  </div>
                </div>{' '}
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao}
                      type="button"
                      value={TextBotao}
                      onClick={() => SalvaAlteraProduto()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      onClick={() => LimparTela()}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <ListItens
        titulogeral={'PRODUTOS CADASTRADOS'}
        nomeColuna1={'ID'}
        nomeColuna2={'NOME'}
        nomeColuna3={'VALOR'}
        dadosGrid={dadosGridProdutos}
        tipo={'1'}
      />
    </div>
  );
};

export default CadProdutos;
