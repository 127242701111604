import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useReducer,
} from 'react';

import { api, setToken as apiSetToken, clearToken } from '../services/api';
import useInterval from '../hooks/useInterval';
import { jwtDecode } from 'jwt-decode';
import { consultaApi } from '../api/consultaApi';
import { BuscaPermissoesPorIDusuario } from '../api/buscaPermissoesApi';
import dayjs from 'dayjs';

const AuthContext = createContext();

const tokenDecoded = localStorage.getItem('accessToken')
  ? jwtDecode(localStorage.getItem('accessToken'))
  : undefined;

function clearLocalStorage() {
  localStorage.removeItem('accessToken');
}

function setTokenLocalStorage(token) {
  localStorage.setItem('accessToken', token);
}

const AuthProvider = ({ children }) => {
  const reducer = useCallback((state, action) => {
    switch (action.type) {
      case 'Loading':
        try {
          if (action.token && action.workspace) {
            setTokenLocalStorage(action.token);
            apiSetToken(action.token);
          }
          return {
            ...state,
            isLoading: false,
            isAuthenticated: !!action.token,
            token: action.token,
            payload: action.payload,
            refreshTokenInterval: action.refreshTokenInterval,
          };
        } catch (e) {
          return {
            ...state,
            isLoading: false,
            isAuthenticated: false,
            token: undefined,
            payload: undefined,
            refreshTokenInterval: undefined,
          };
        }

      case 'Login':
        setTokenLocalStorage(action.token);
        apiSetToken(action.token);

        return {
          ...state,
          isAuthenticated: true,
          token: action.token,
          payload: action.payload,
          refreshTokenInterval: action.refreshTokenInterval,
        };

      case 'RefreshToken':
        setTokenLocalStorage(action.token);
        apiSetToken(action.token);

        return {
          ...state,
          token: action.token,
          payload: action.payload,
          refreshTokenInterval: action.refreshTokenInterval,
        };

      case 'Logout':
      default:
        clearLocalStorage();
        clearToken();

        return {
          ...state,
          isAuthenticated: false,
          token: undefined,
          payload: undefined,
          refreshTokenInterval: undefined,
        };
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    isAuthenticated: false,
    token: undefined,
    payload: undefined,
    refreshTokenInterval: undefined,
  });

  useEffect(() => {
    const initStorageToken = async () => {
      try {
        const storageToken = localStorage.getItem('accessToken');

        if (!storageToken) throw Error('token not found');

        apiSetToken(storageToken);

        const response = await refreshToken(storageToken);

        if (!response.auth) throw Error('invalid storage token');

        const token = response.token;
        const payload = jwtDecode(token);

        dispatch({
          type: 'Loading',
          token,
          payload,
          refreshTokenInterval: payload.exp,
        });
      } catch (e) {
        clearToken();
        dispatch({ type: 'Loading' });
      }
    };

    initStorageToken();
  }, []);

  async function login(login, senha) {
    try {
      const response = await api.post('/get-token', { login, senha });

      if (!response) {
        console.log('Error', response);
        throw Error(response);
      }
      if (response.data.auth) {
        const token = response.data.token;
        const payload = jwtDecode(token);

        console.log('Primeiro Token de Acesso', token);

        dispatch({
          type: 'Login',
          token,
          payload,
          refreshTokenInterval: response.data.expires_in,
        });
      }
    } catch (error) {
      console.log('catch context', error);
      console.error('Erro ao fazer a solicitação de login', error.response);
      return error.response;
    }
  }

  const logout = async () => {
    console.log('LOGOUT');
    dispatch({ type: 'Logout' });
  };

  async function refreshToken(token) {
    try {
      const response = await api.post('/refreshToken', { token });
      if (response) {
        if (response.status === 200 && response.data.auth) {
          return response.data;
        } else {
          logout();
        }
      } else {
        console.log('Reponse');
        logout();
      }
    } catch (error) {
      console.log('Erro ao realizar RefreshToken', error);
      logout();
    }
  }

  useInterval(
    async () => {
      try {
        const response = await refreshToken(state.token);
        const token = response.data.token;
        const payload = jwtDecode(token);

        dispatch({ type: 'RefreshToken', token, payload });
      } catch (error) {
        dispatch({ type: 'Logout' });
      }
    },
    state.isAuthenticated ? state.refreshTokenInterval : null
  );

  const hasPermissionMenu = (permission) => {
    //console.log('permissão', userPermission);

    if (!state.payload) {
      return false;
    }
    const contemMenu = state.payload.menus.some(
      (menus) => menus.menu === permission
    );

    if (!!permission && contemMenu) {
      //return userPermission.some((permissao) => permissao.tela === permission);
      return true;
    } else {
      return false;
    }
  };

  const value = {
    isAuthenticated: state.isAuthenticated,
    payload: state.payload,
    login,
    logout,
    hasPermissionMenu,
  };

  return (
    <AuthContext.Provider value={value}>
      {state.isLoading ? <div>Loading...</div> : children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => useContext(AuthContext);

export { AuthProvider, useAuthContext };
