import React, { useState, useEffect, useRef } from 'react';
import { api } from '../../services/api';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';
import { ListItens } from '../../components/layout/ListItens';
import { useListContext } from '../../context/ListContext.jsx';

const CadFiliais = () => {
  const [IDfilial, setfilial] = useState('');
  const [nomefilial, setnomefilial] = useState('');
  const [endereco, setendereco] = useState('');
  const [cidade, setcidade] = useState('');
  const [uf, setuf] = useState('');
  const [usuario, setusuario] = useState('');
  const [senha, setsenha] = useState('');
  const [telefone, settelefone] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [facebook, setfacebook] = useState('');
  const [instagram, setinstagram] = useState('');
  const [latlong, setlatlong] = useState('');

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const { Opreracao, setOpreracao } = useListContext();

  const [dadosGrid, setdadosGrid] = useState([]);

  const fileInputRef = useRef(null);

  useEffect(function () {
    carregaGridFiliais();
  }, []);

  useEffect(
    function () {
      VerificaOperacao();
    },
    [Opreracao]
  );

  async function VerificaOperacao() {
    let ok;
    let dados = Opreracao;
    if (dados !== '') {
      ok = await CarregaDados(dados);
      setOpreracao('');
    }
  }

  async function CarregaDados(Objeto) {
    let retorno = await BuscaFiliaisID(Objeto.Id);

    setfilial(retorno[0].IDfilial);
    setnomefilial(retorno[0].nome);
    setendereco(retorno[0].endereco);
    setcidade(retorno[0].cidade);
    setuf(retorno[0].uf);
    setusuario(retorno[0].usuario);
    setsenha(retorno[0].senha);
    settelefone(retorno[0].telefone);
    setfacebook(retorno[0].linkFacebook);
    setinstagram(retorno[0].linkInstagram);
    setlatlong(retorno[0].linkCoordenadas);

    if (Objeto.Operacao === 'Deletar') {
      setTextBotao('Deletar');
      setTipoBotao('btn btn-danger');
    } else {
      setTextBotao('Alterar');
      setTipoBotao('btn btn-secondary');
    }
  }

  async function BuscaFiliaisID(Id) {
    return await api
      .get('/FilialID/' + Id)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeleteFilial() {
    return await api
      .delete('/Filial/' + IDfilial)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaFiliais() {
    return await api
      .get('/Filiais')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvaAlteraFilial() {
    if (TextBotao === 'Deletar') {
      if (
        window.confirm(
          'Essa opção vai DELETAR definitivamente esse cadastro. \n\nClique OK para Prosseguir.'
        )
      ) {
        await DeleteFilial();
        carregaGridFiliais();
        LimparTela();
      }
    } else {
      let retorno;

      if (IDfilial === '') {
        retorno = await InserirFilial();
        carregaGridFiliais();
        LimparTela();
      } else {
        retorno = await AlterarFilial();
        carregaGridFiliais();
        LimparTela();
      }
    }
  }

  async function InserirFilial() {
    let formData = new FormData();

    formData.append('nome', nomefilial);
    formData.append('endereco', endereco);
    formData.append('cidade', cidade);
    formData.append('uf', uf);
    formData.append('telefone', telefone);
    formData.append('usuario', usuario);
    formData.append('senha', senha);
    formData.append('linkFacebook', facebook);
    formData.append('linkInstagram', instagram);
    formData.append('linkCoordenadas', latlong);
    formData.append('imgfilial', imageFile);

    try {
      const response = await api.post('/Filial', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Importante definir o header
        },
      });

      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function AlterarFilial() {
    let formData = new FormData();

    formData.append('IDfilial', IDfilial);
    formData.append('nome', nomefilial);
    formData.append('endereco', endereco);
    formData.append('cidade', cidade);
    formData.append('uf', uf);
    formData.append('telefone', telefone);
    formData.append('usuario', usuario);
    formData.append('senha', senha);
    formData.append('linkFacebook', facebook);
    formData.append('linkInstagram', instagram);
    formData.append('linkCoordenadas', latlong);
    if (!!imageFile) {
      formData.append('imgfilial', imageFile);
    }

    try {
      const response = await api.put('/FilialAlterar', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function carregaGridFiliais() {
    var filiais = [];

    let retorno = await BuscaFiliais();
    if (retorno) {
      retorno.forEach((element) => {
        filiais.push({
          id: element.IDfilial,
          coluna2: element.nome,
          coluna3: element.cidade,
        });
      });
    }

    setdadosGrid(filiais);
  }

  function LimparTela() {
    setfilial('');
    setnomefilial('');
    setendereco('');
    setcidade('');
    setuf('');
    setusuario('');
    setsenha('');
    settelefone('');
    setfacebook('');
    setinstagram('');
    setlatlong('');
    setImageFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setTextBotao('Salvar');
    setTipoBotao('btn btn-success');
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        className="row"
        style={{
          padding: '2%',
          paddingBottom: '0',
          backgroundColor: 'rgb(243, 240, 231)',
        }}
      >
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div id="formulario">
            <form id="formulario" autoComplete="off">
              <fieldset>
                <legend>CADASTRO - FRANQUEADOS</legend>
                <input
                  id="IDfilial"
                  style={{ display: 'none' }}
                  type="text"
                  value={IDfilial}
                  onChange={(e) => setfilial(e.target.value)}
                />
                <br />
                <br />
                <label>Nome Franqueado :</label>
                <br />
                <input
                  id="nomefilial"
                  className="width_cemporcento form-control"
                  type="text"
                  value={nomefilial}
                  onChange={(e) => setnomefilial(e.target.value)}
                />
                <br />
                <br />
                <label>Endereço :</label>
                <br />
                <input
                  id="endereco"
                  className="width_cemporcento form-control"
                  type="text"
                  value={endereco}
                  onChange={(e) => setendereco(e.target.value)}
                />
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-6">
                    <label>Cidade :</label>
                    <br />
                    <input
                      id="cidade"
                      className="width_cemporcento form-control"
                      type="text"
                      value={cidade}
                      onChange={(e) => setcidade(e.target.value)}
                    />
                    <br />
                    <br />
                  </div>

                  <div className="col-sm-6">
                    <label>UF :</label>
                    <br />
                    <input
                      id="uf"
                      className="width_cemporcento form-control"
                      type="text"
                      value={uf}
                      onChange={(e) => setuf(e.target.value)}
                    />
                    <br />
                    <br />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-3">
                    <label>Telefone :</label>
                    <br />
                    <input
                      id="telefone"
                      className="width_cemporcento form-control"
                      type="text"
                      value={telefone}
                      onChange={(e) => settelefone(e.target.value)}
                    />
                  </div>
                  <div className="col-sm-9">
                    <label>Imagem :</label>
                    <br />
                    <input
                      id="imgfilial"
                      className="width_cemporcento form-control"
                      type="file"
                      accept="image/*"
                      ref={fileInputRef} // Atribui a ref ao input de arquivo
                      onChange={(e) => setImageFile(e.target.files[0])}
                    />
                  </div>
                </div>{' '}
                <br />
                <br />
                <label>Facebook :</label>
                <br />
                <input
                  id="facebook"
                  className="width_cemporcento form-control"
                  type="text"
                  value={facebook}
                  onChange={(e) => setfacebook(e.target.value)}
                />
                <br />
                <br />
                <label>Instagram :</label>
                <br />
                <input
                  id="instragram"
                  className="width_cemporcento form-control"
                  type="text"
                  value={instagram}
                  onChange={(e) => setinstagram(e.target.value)}
                />
                <br />
                <br />
                <label>Latitude, Longitude :</label>
                <br />
                <input
                  id="latlong"
                  className="width_cemporcento form-control"
                  type="text"
                  value={latlong}
                  onChange={(e) => setlatlong(e.target.value)}
                />
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao}
                      type="button"
                      value={TextBotao}
                      onClick={() => SalvaAlteraFilial()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      onClick={() => LimparTela()}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <ListItens
        titulogeral={'FRANQUIAS CADASTRADAS'}
        nomeColuna1={'ID'}
        nomeColuna2={'NOME'}
        nomeColuna3={'CIDADE'}
        dadosGrid={dadosGrid}
        tipo={'0'}
      />
    </div>
  );
};

export default CadFiliais;
