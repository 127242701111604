import React from 'react';
import { Bar } from 'react-chartjs-2';

class BarChart extends React.Component {
  render() {
    const { min, current, max } = this.props;

    const data = {
      labels: ['Mínimo', 'Estoque', 'Máximo'],
      datasets: [
        {
          label: 'Controle De Estoque',
          backgroundColor: ['#FF6F61', '#4CAF50', '#61AFFF'],
          borderColor: 'rgba(0,0,0,0)',
          borderWidth: 1,
          hoverBackgroundColor: ['#FF6F61', '#4CAF50', '#61AFFF'],
          data: [min, current, max],
        },
      ],
    };

    const options = {
      scales: {
        y: {
          beginAtZero: true,
          max: Math.max(min, current, max) + 10, // Define o valor máximo do eixo y
        },
      },
    };

    return (
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    );
  }
}

export default BarChart;
