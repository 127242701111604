import React from 'react';
import './styles.css';
import { useListContext } from '../../context/ListContext.jsx';

export const ListItens = ({
  titulogeral,
  nomeColuna1,
  nomeColuna2,
  nomeColuna3,
  dadosGrid,
  tipo,
}) => {
  const { Operacao, setOpreracao } = useListContext();

  //tipo: 0 -> Filiais
  //tipo: 1 -> Produtos
  //tipo: 2 -> Videos
  //tipo: 3 -> Usuários
  //tipo: 4 -> Cargos
  //tipo: 5 -> Ativos
  async function Editar(tipo, id) {
    let valor = { Operacao: 'Editar', Tipo: tipo, Id: id };
    setOpreracao(valor);
  }

  async function Delete(tipo, id) {
    let valor = { Operacao: 'Deletar', Tipo: tipo, Id: id };
    setOpreracao(valor);
  }

  return (
    <div
      className="row"
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        paddingLeft: '2%',
        paddingRight: '2%',
        paddingBottom: '3%',
      }}
    >
      <div className="col-sm-3" />
      <div className="col-sm-9" style={{ padding: '1em' }}>
        <div id="grid_produtos">
          <br />
          <legend>{titulogeral}</legend>
          <br />
          <br />
          <table>
            <thead>
              <tr>
                <th colSpan={1} style={{ width: '15%' }}>
                  {nomeColuna1}
                </th>
                <th colSpan={1} style={{ width: '40%' }}>
                  {nomeColuna2}
                </th>
                <th colSpan={1} style={{ width: '20%' }}>
                  {nomeColuna3}
                </th>
                <th colSpan={1} style={{ width: '25%' }} />
              </tr>
            </thead>
            <tbody>
              {dadosGrid.map((dados) => (
                <tr key={dados.id}>
                  <td style={{ textAlign: 'center' }}>{dados.id}</td>
                  <td style={{ textAlign: 'start' }}>{dados.coluna2}</td>
                  <td style={{ textAlign: 'start' }}>{dados.coluna3}</td>
                  <td style={{ textAlign: 'center' }}>
                    <i
                      className="bi bi-pencil"
                      style={{
                        color: 'black',
                        paddingRight: '10px',
                        fontWeight: 'bold',
                        cursor: 'pointer',
                      }}
                      onClick={() => Editar(tipo, dados.id)}
                    />
                    <i
                      className="bi bi-x-octagon"
                      style={{
                        color: 'black',
                        paddingRight: '10px',
                        paddingLeft: '10px',
                        cursor: 'pointer',
                      }}
                      onClick={() => Delete(tipo, dados.id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
