import React from 'react';
import { Link } from 'react-router-dom';

import '../../main.css';

function Footer() {
  return (
    <>
      {/*<!-- Footer Section Start -->*/}
      <footer>
        <div className="container">
          <div className="row">
            {/*<!-- Footer Links -->*/}
            <div className="col-lg-7 col-sm-7 col-xs-12">
              <ul className="footer-links">
                <li>
                  <a className="is-active" href="/Home" exact="true">
                    Início
                  </a>
                </li>
                <li>
                  <a href="/Home/#services">Destaques</a>
                </li>
                <li>
                  <a href="/Home/#features">Sobre</a>
                </li>
                <li>
                  <a href="/vacinas">Vacinas</a>
                </li>
                <li>
                  <a href="/franqueado">Franqueado</a>
                </li>
                <li>
                  <a href="/Home/#team">Unidades</a>
                </li>
                <li>
                  <a href="/Home/#contact">Contato</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-5 col-sm-5 col-xs-12">
              <div className="copyright">
                <p>
                  Todos os direitos reservados © - Previmune Clínica de Vacinas
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*<!-- Footer Section End -->*/}
    </>
  );
}

export default Footer;
