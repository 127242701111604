import React from 'react';
import Icons from './Icons';

import '../../main.css';

function Contact() {
  return (
    <>
      {/*<!-- Contact Section Start -->*/}
      <section
        id="contact"
        className="section"
        data-stellar-background-ratio="-0.2"
      >
        <div className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="contact-us">
                  <h3>Contato</h3>
                  <div className="contact-address">
                    <p>CENTRAL - Passos-MG</p>
                    <p className="endereco">
                      Endereço:{' '}
                      <span>
                        Av. Com. Fco.Avelino Maia, 1900 - Centro - Passos-MG
                      </span>
                    </p>
                    <p className="phone">
                      Telefone: <span>(35) 98836-1541</span>
                    </p>
                    <p className="email">
                      E-mail: <span>contato@previmune.com.br</span>
                    </p>
                  </div>
                  <div className="social-icons">
                    <ul>
                      <li className="facebook">
                        <a href="https://www.facebook.com/andreluiz.silva.71868">
                          <Icons icon="facebook" />
                        </a>
                      </li>
                      <li className="instagram">
                        <a href="https://www.instagram.com/previmune/">
                          <Icons icon="instagram" />
                        </a>
                      </li>
                      <li className="maps">
                        <a href="https://goo.gl/maps/kUUyZtnufrATHpW5A">
                          <Icons icon="mapMarker" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-sm-6 col-xs-12">
                <div className="contact-block">
                  <form id="contactForm">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Seu Nome"
                            required
                            data-error="Por favor, insira seu nome"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            placeholder="Seu Email"
                            id="email"
                            className="form-control"
                            name="email"
                            required
                            data-error="Por favor, insira seu email"
                          ></input>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            id="message"
                            placeholder="Sua Mensagem"
                            rows="8"
                            data-error="Escreva sua mensagem"
                            required
                          ></textarea>
                          <div className="help-block with-errors"></div>
                        </div>
                        <div className="submit-button text-center">
                          <button
                            className="btn-common"
                            id="submit"
                            type="submit"
                          >
                            Enviar Mensagem
                          </button>
                          <div
                            id="msgSubmit"
                            className="h3 text-center hidden"
                          ></div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*<!-- Contact Section End -->*/}
    </>
  );
}

export default Contact;
