import { consultaApi } from './consultaApi';

async function BuscaFiliaisApi(IDFilial = null) {
  let response;
  try {
    //await BuscaToken();
  } catch (error) {
    console.log('Erro ao consultar token', error);
  }
  try {
    const rota = !!IDFilial ? '/FilialID/' + IDFilial : '/Filiais';

    response = await consultaApi('get', rota);

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(
        'Retorno não esperado:',
        response.status,
        response.statusText
      );
      return response;
    }
  } catch (error) {
    console.log('Erro ao consultar tabela filiais', error);
    return null;
  }
}

export default BuscaFiliaisApi;
