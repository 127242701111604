import React, { useEffect, useState } from 'react';
import HeaderSite from '../../components/layout/HeaderMenu';
import { api } from '../../services/api';
import imagemPadrao from '../../imagens/padrao_video.jpg';
import { useAuthContext } from '../../context/AuthContext';
import './styles.css';

const Videos = () => {
  const [videos, setvideos] = useState([]);

  const { payload } = useAuthContext();

  const idFilialUser = payload.user.IDfilial;

  useEffect(function () {
    carregaVideos();
  }, []);

  async function carregaVideos() {
    let idfilial = idFilialUser;
    let dadosVideo = await buscaDadosVideos(idfilial);
    setvideos(dadosVideo);
  }

  async function buscaDadosVideos(IDfilial) {
    return await api
      .get('/VideosIDfilial/' + IDfilial)
      .then(function (response) {
        console.log('videos: ', response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        padding: '2%',
        width: '100%',
        height: '100%',
      }}
    >
      <div className="row">
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div
            id="painelvideos"
            className="row"
            style={{ padding: '10px', width: '100%' }}
          >
            {!!videos &&
              videos.map((dados) => {
                const imagemURL = dados.nomeImg
                  ? `/assets/images/videos/${dados.nomeImg}`
                  : imagemPadrao;
                return (
                  <div className="col-sm-3" key={dados.id}>
                    <a
                      href={dados.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={imagemURL} alt="imagem video" />
                      <h5>{dados.titulo}</h5>
                      <p className="videoDescricao">{dados.descricao}</p>
                    </a>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Videos;
