import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';
import { ListItens } from '../../components/layout/ListItens';
import { useListContext } from '../../context/ListContext.jsx';
import CheckboxList from '../../components/layout/checkboxList';
import sweetAlert from '../../components/layout/sweetAlert.js';

const cadCargosMenus = () => {
  const [idcargo, setIdCargo] = useState('');
  const [nomeCargo, setNomeCargo] = useState('');
  const [descricaoCargo, setDescricaoCargo] = useState('');
  const [dadosGridCargo, setdadosGridCargo] = useState([]);
  const [listaMenus, setListaMenus] = useState([]);

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const { Opreracao, setOpreracao, selectedOptions, setSelectedOptions } =
    useListContext();

  const handleCheckboxChange = (selectedOptions) => {
    //console.log('Opções selecionadas:', selectedOptions);
  };

  useEffect(function () {
    carregaGridCargos();
    carregaListMenus();
  }, []);

  useEffect(
    function () {
      VerificaOperacao();
    },
    [Opreracao]
  );

  async function VerificaOperacao() {
    let ok;
    let dados = Opreracao;

    if (dados !== '') {
      ok = await CarregaDados(dados);
      setOpreracao('');
    }
  }

  async function CarregaDados(Objeto) {
    let retorno;

    retorno = await BuscaCargoID(Objeto.Id);

    setIdCargo(retorno[0].IDcargo);
    setNomeCargo(retorno[0].nome);
    setDescricaoCargo(retorno[0].descricao);

    let dados = [];
    retorno = await BuscaMenuCargoIDCargo(Objeto.Id);
    retorno.map(function (l) {
      dados.push(l.IDmenu);
    });

    setSelectedOptions(dados);

    if (Objeto.Operacao === 'Deletar') {
      setTextBotao('Deletar');
      setTipoBotao('btn btn-danger');
    } else {
      setTextBotao('Alterar');
      setTipoBotao('btn btn-secondary');
    }
  }

  async function BuscaCargos() {
    return await api
      .get('/Cargos')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaMenus() {
    return await api
      .get('/Menus')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        return error;
      });
  }

  async function BuscaCargoID(Id) {
    return await api
      .get('/CargoID/' + Id)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaMenuCargoIDMenu(IdMenu) {
    return await api
      .get('/MenuCargoIDMenu/' + IdMenu)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaMenuCargoIDCargo(IdCargo) {
    return await api
      .get('/MenuCargoIDCargo/' + IdCargo)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function carregaListMenus() {
    let Menus = [];

    let retorno = await BuscaMenus();
    retorno.forEach((element) => {
      Menus.push({
        id: element.IDmenu,
        titulo:
          element.descricao.charAt(0).toUpperCase() +
          element.descricao.substring(1),
        //DEIXAR PRIMEIRA LETRA MAIUSCULA
        //string.charAt(0).toUpperCase() + string.substring(1);
      });
    });

    setListaMenus(Menus);
  }

  async function deleteCargos() {
    return await api
      .delete('/Cargos/' + idcargo)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvaAlteraCargo() {
    let retorno;
    if (TextBotao === 'Deletar') {
      if (
        window.confirm(
          'Essa opção vai DELETAR definitivamente esse cadastro. \n\nClique OK para Prosseguir.'
        )
      ) {
        retorno = await deleteCargos();
        //DELETA MENUS_CARGO DESSE CARGO
        if (retorno.Delete === 'OK') {
          await DeletaPermissoesMenus(idcargo);
        }
        carregaGridCargos();
        LimparTela();
      }
    } else {
      if (selectedOptions.length > 0) {
        if (idcargo === '') {
          retorno = await InserirCargo();
          //INSERE MENUS_CARGO
          //console.log('inserir', retorno);
          if (retorno.insert === 'OK') {
            await AtualizaPermissoesMenus(retorno.IDcargo);
          }
          carregaGridCargos();
          LimparTela();
        } else {
          retorno = await AlterarCargo();
          //ATUALIZA MENUS_CARGO
          if (retorno.update === 'OK') {
            await AtualizaPermissoesMenus(idcargo);
          }
          carregaGridCargos();
          LimparTela();
        }
      } else {
        //alert('Favor preencher os menus que esse cargo tem permissão!');
        sweetAlert(
          'warning',
          'Atenção',
          'Favor preencher os menus que esse cargo tem permissão!'
        );
      }
    }
  }

  async function InserirCargo() {
    let dadosPost = {
      nome: nomeCargo,
      descricao: descricaoCargo,
    };

    if (!nomeCargo || !descricaoCargo) {
      //alert('Preencha todos os campos!');
      sweetAlert('warning', 'Atenção', 'Preencha todos os campos!');
      return;
    }

    return await api
      .post('/Cargos', dadosPost)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function AlterarCargo() {
    const dados = {
      IDcargo: idcargo,
      nome: nomeCargo,
      descricao: descricaoCargo,
    };

    try {
      return await api
        .put('/CargoAlterar', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function AtualizaPermissoesMenus(IDcargo) {
    let retorno;

    retorno = await DeletaPermissoesMenus(IDcargo);
    if (retorno.Delete === 'OK') {
      selectedOptions.map(function (id_) {
        InsertPermissoesMenus(IDcargo, id_);
      });
      return { PermissoesMenus: 'OK' };
    } else {
      return { Error: 'Atualização não realizada(DELETE)' };
    }
  }

  async function InsertPermissoesMenus(IDcargo, IDmenu) {
    let dadosPost = {
      IDcargo: IDcargo,
      IDmenu: IDmenu,
    };

    return await api
      .post('/MenusCargo', dadosPost)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeletaPermissoesMenus(IDcargo) {
    return await api
      .delete('/MenusCargo/' + IDcargo)
      .then(function (response) {
        console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function carregaGridCargos() {
    let cargos = [];

    let retorno = await BuscaCargos();
    retorno.forEach((element) => {
      cargos.push({
        id: element.IDcargo,
        coluna2: element.nome,
        coluna3:
          element.descricao.length > 15
            ? element.descricao.substring(0, 15) + '...'
            : element.descricao,
      });
    });

    setdadosGridCargo(cargos);
  }

  function LimparTela() {
    setIdCargo('');
    setNomeCargo('');
    setDescricaoCargo('');
    setTextBotao('Salvar');
    setTipoBotao('btn btn-success');
    setSelectedOptions([]);
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        className="row"
        style={{
          padding: '2%',
          paddingBottom: '0',
          backgroundColor: 'rgb(243, 240, 231)',
        }}
      >
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div id="formulario">
            <form id="formulario" autoComplete="off">
              <fieldset>
                <legend>CADASTRO - CARGOS</legend>
                <input
                  id="idcargo"
                  style={{ display: 'none' }}
                  type="text"
                  value={idcargo}
                  onChange={(e) => setIdCargo(e.target.value)}
                />
                <br />
                <br />
                <label>Nome do Cargo:</label>
                <br />
                <input
                  id="nomecargo"
                  className="width_cemporcento  form-control"
                  type="text"
                  value={nomeCargo}
                  onChange={(e) => setNomeCargo(e.target.value)}
                />
                <br />
                <br />
                <label>Descrição:</label>
                <br />
                <input
                  id="descricaocargo"
                  className="width_cemporcento form-control"
                  type="text"
                  maxLength={80}
                  value={descricaoCargo}
                  onChange={(e) => setDescricaoCargo(e.target.value)}
                />
                <br />
                <br />

                <div className="row">
                  <div className="col-sm-6" style={{ paddingLeft: '15px' }}>
                    <CheckboxList
                      options={listaMenus}
                      onCheckboxChange={handleCheckboxChange}
                      titulo={'MENUS DE ACESSO'}
                    />
                  </div>
                </div>

                <br />
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao}
                      type="button"
                      value={TextBotao}
                      onClick={() => SalvaAlteraCargo()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      onClick={() => LimparTela()}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <ListItens
        titulogeral={'CARGOS CADASTRADOS'}
        nomeColuna1={'ID'}
        nomeColuna2={'NOME'}
        nomeColuna3={'DESCRIÇÃO'}
        dadosGrid={dadosGridCargo}
        tipo={'4'}
      />
    </div>
  );
};

export default cadCargosMenus;
