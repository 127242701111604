import React, { useEffect } from 'react';
import './CheckboxList.css'; // Importe o arquivo CSS para estilos
import { useListContext } from '../../../context/ListContext.jsx';

const CheckboxList = ({ options, onCheckboxChange, titulo }) => {
  const { selectedOptions, setSelectedOptions } = useListContext();

  useEffect(function () {
    //handleCheckboxChange(9);
  }, []);

  const handleCheckboxChange = (id) => {
    if (selectedOptions.includes(id)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== id));
    } else {
      setSelectedOptions([id]);
    }
    onCheckboxChange([id]);
  };

  return (
    <div id="checkboxlist-princ">
      <p>{titulo}</p>
      <div className="checkbox-list-container">
        <div className="checkbox-list">
          {options.map((option) => (
            <div key={option.id} className="checkbox-item">
              <input
                type="checkbox"
                id={`checkbox-${option.id}`}
                value={option.titulo}
                checked={selectedOptions.includes(option.id)}
                onChange={() => handleCheckboxChange(option.id)}
              />
              <label htmlFor={`checkbox-${option.id}`}>{option.titulo}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CheckboxList;
