import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React from 'react';
import { useNavigate, Link } from 'react-router-dom';

import icon1 from '../../imagens/img/fotos/icons/1.png';
import icon2 from '../../imagens/img/fotos/icons/2.png';
import icon3 from '../../imagens/img/fotos/icons/3.png';
import icon4 from '../../imagens/img/fotos/icons/5.png';
import iconCarrinho from '../../imagens/img/fotos/icons/carrinho.png';

import { FaRegHandshake } from 'react-icons/fa';

import '../animate.css';
import '../site.css';
import '../../main.css';
import '../responsive.css';

import HeaderSite from '../../components/layout/HeaderSite';
import SaibaMais from '../../components/layout/SaibaMais';
import Contact from '../../components/layout/Contact';
import Unidades from '../../components/layout/Unidades';
import Footer from '../../components/layout/Footer';
import GoToTop from '../../components/layout/GoToTop';

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      {' '}
      {/*React.Fragment*/}
      <HeaderSite></HeaderSite>
      <section id="services" className="section">
        <Container>
          <div className="section-header">
            <h2
              className="section-title wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
            >
              Vantagens de tomar vacina em uma clínica confiável
            </h2>
            <hr className="lines wow zoomIn" data-wow-delay="0.3s" />
            <p
              className="section-subtitle wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
            >
              A clínica Previmune preza pela proteção de sua família, oferecendo
              produtos de qualidade.
            </p>
          </div>
          <Row>
            <Col md={3} sm={6}>
              <div className="item-boxes wow fadeInDown" data-wow-delay="0.2s">
                <span>
                  <img src={icon1} alt="1"></img>
                </span>
                <h4>
                  Maior <br /> Eficácia
                </h4>
                <p>
                  Por termos acordos com grandes laboratórios, temos condições
                  de adquirir vacinas com tecnologias mais recentes, possuindo
                  respostas imunes mais eficientes, aumentando o nível de
                  imunização.
                </p>
              </div>
            </Col>
            <Col md={3} sm={6}>
              <div className="item-boxes wow fadeInDown" data-wow-delay="0.8s">
                <span>
                  <img src={icon2} alt="2"></img>
                </span>
                <h4>
                  Menos efeitos
                  <br />
                  colaterais
                </h4>
                <p>
                  Nas vacinas com tecnologias de ponta, os efeitos colaterais
                  acabam sendo menores, gerando mais conforto, principalmente em
                  crianças e bebês, que conseguem seguir uma rotina pós-vacina
                  mais tranquila.{' '}
                </p>
              </div>
            </Col>
            <Col md={3} sm={6}>
              <div className="item-boxes wow fadeInDown" data-wow-delay="1.2s">
                <span>
                  <img src={icon3} alt="3"></img>
                </span>
                <h4>Vacinas que não estão na rede pública</h4>
                <p>
                  Contamos com portfólio completo de imunizantes que em alguns
                  casos não estão presentes na rede publica, seja com a própria
                  vacina ou para determinadas faixas etárias.
                </p>
              </div>
            </Col>
            <Col md={3} sm={6}>
              <div className="item-boxes wow fadeInDown" data-wow-delay="1.5s">
                <span>
                  <img src={icon4} alt="4"></img>
                </span>
                <h4>
                  Atendimento
                  <br />
                  diferenciado
                </h4>
                <p>
                  Contamos com uma equipe de profissionais altamente
                  capacitados, podemos, também, oferecer atendimento com ou sem
                  hora marcada, ou até mesmo levar suas vacinas e aplicá-las no
                  conforto do seu lar.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <SaibaMais></SaibaMais>
      {/*<!-- Start Pricing Table Section -->*/}
      <section id="pricing" className="section pricing-section">
        <Container>
          <div className="section-header">
            <h2 className="section-title">Compre aqui sua vacina</h2>
            <hr className="lines" />
            <p className="section-subtitle">
              Disponibilizamos em nosso site o mais completo catálogo de
              vacinas.
              <br />
              Você pode selecionar a vacina desejada e fazer o agendamento com
              nossa equipe <br />
              para que um de nossos profissionais faça a aplicação no conforto
              de sua residência.
            </p>
          </div>
          <div className="pricing-button">
            {/* <Link to="/vacinas" style={{ textDecoration: 'none' }}> */}
            <button
              className="btn btn-common"
              id="button"
              type="button"
              onClick={() => {
                navigate('/Vacinas');
              }}
            >
              <img src={iconCarrinho} alt="carrinho" />
              Comprar
            </button>
            {/* </Link> */}
          </div>
        </Container>
      </section>
      {/*<!-- End Pricing Table Section --></div>*/}
      {/* <!-- Start Video promo Section --> */}
      <section id="franqueado_home" className="video-promo section">
        <div className="img">
          <Container>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="video-promo-content text-center">
                  <h2
                    className="wow zoomIn"
                    data-wow-duration="1000ms"
                    data-wow-delay="100ms"
                  >
                    Seja um de nossos franqueados
                  </h2>
                  <p
                    className="wow zoomIn"
                    data-wow-duration="1000ms"
                    data-wow-delay="100ms"
                  >
                    Empresa voltada à satisfação dos seus clientes na área de
                    vacinação, <br />
                    apresentamos modelo com gestão enxuta e baixo custo de
                    investimento. <br />A parceria com a Previmune garante
                    rentabilidade e credibilidade às atividades de sua clínica
                    ou consultório.
                    <br />
                    Clique abaixo e preencha um rápido formulário para conhecer
                    melhor essa grande oportunidade.
                  </p>
                </div>
                <div className="franqueado-button">
                  {/* <!--<button className="btn btn-common" id="button" type="button" onclick="window.location.href='~/Views/Home/Franqueado.cshtml'"><i className="fa fa-handshake-o"></i>Clique Aqui</button>  --> */}
                  <Link to="/franqueado/#" style={{ textDecoration: 'none' }}>
                    <button
                      className="btn btn-common"
                      id="button"
                      type="button"
                    >
                      {/*onclick="location.href='@Url.Action("Franqueado","Home")'"*/}
                      <FaRegHandshake
                        style={{ fontSize: '25px', color: '#000' }}
                      />
                      Clique Aqui
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </section>
      {/* <!-- End Video Promo Section --> */}
      <Unidades></Unidades>
      <Contact></Contact>
      <Footer></Footer>
      <GoToTop></GoToTop>
    </>
  );
};
export default Home;
