import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import sweetAlert from '../../components/layout/sweetAlert';

import './login.css';

import { useAuthContext } from '../../context/AuthContext';

//import api from '../../services/api';
//import { BuscaToken, consultaApi } from '../../api/consultaApi';

const Adm = () => {
  const navigate = useNavigate();
  const [usuario, setUsuario] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuthContext();

  async function TentaLogarToken() {
    if (!usuario | !password) {
      //alert('Preencha todos os campos');
      sweetAlert('warning', 'Atenção', 'Preencha todos os campos');
      return;
    }

    const res = await login(usuario, password);

    if (res && !res.auth) {
      //alert('Atenção! ' + res.data.message);
      sweetAlert('warning', 'Atenção', res.data.message);
    } else {
      navigate('/Menu');
    }
  }

  function VoltarHome() {
    navigate('/Home');
  }

  //Inibe envio de formulario
  function handleSubmit(event) {
    event.preventDefault();
  }

  return (
    <>
      <div
        className="IndexPageDiv"
        // style={{
        //   backgroundImage: `url(${imgAreaIndex})`,
        //   height: "100vh",
        //   width: "100%",
        //   backgroundSize: "100%",
        // }}
      >
        <div className="indexFront">
          <div className="div-container">
            <div align="right" className="row">
              <div className="col-sm-4"></div>
              <div className="col-sm-4 areaLoginAdm">
                <br />
                <br />
                <div align="center">
                  <Form onSubmit={handleSubmit}>
                    <Form.Group
                      className="groups"
                      size="lg"
                      controlId="usuario"
                    >
                      <Form.Control
                        autoFocus
                        type="text"
                        value={usuario}
                        onChange={(e) => setUsuario(e.target.value)}
                        placeholder="Login"
                        style={{
                          color: 'black',
                          borderColor: 'gray',
                          textAlign: 'center',
                          borderRadius: '0',
                        }}
                      />
                    </Form.Group>
                    <br />
                    <Form.Group
                      className="groups"
                      size="lg"
                      controlId="password"
                    >
                      <Form.Control
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Senha"
                        style={{
                          color: 'black',
                          borderColor: 'gray',
                          textAlign: 'center',
                          borderRadius: '0',
                        }}
                      />
                    </Form.Group>
                    <br />
                    <div className="btnArea">
                      <Button
                        size="lg"
                        className="btnLogin"
                        type="submit"
                        //onClick={() => buscaUsuario()}
                        //onClick={() => TentaLogar()}
                        onClick={() => TentaLogarToken()}
                        disabled={!usuario || !password} // Impede o envio sem usuário ou senha
                      >
                        Entrar
                      </Button>
                      <Button
                        size="lg"
                        className="btnVoltar"
                        type="button"
                        onClick={() => VoltarHome()}
                      >
                        Voltar
                      </Button>
                    </div>
                    <br />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Adm;
