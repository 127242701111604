import React, { useState, useEffect } from 'react';

import Icons from './Icons';

import BuscaFiliaisApi from '../../api/buscaFiliaisApi';

/*import unidadePassos from '../../imagens/img/fotos/fotos/clinica/unidades/unidadePassos2.jpg';
import unidadeSaoCarlos from '../../imagens/img/fotos/fotos/clinica/unidades/unidadeSaoCarlos.jpg';
import unidadeBage from '../../imagens/img/fotos/fotos/clinica/unidades/unidadeBage.png';
import unidadeTresPontas from '../../imagens/img/fotos/fotos/clinica/unidades/unidadeTresPontas.jpg';
import unidadeVitoriaDaConquista from '../../imagens/img/fotos/fotos/clinica/unidades/unidadeVitoriaConquista.jpg';
import unidadePatoBranco from '../../imagens/img/fotos/fotos/clinica/unidades/unidadePatoBranco.jpg';
*/

import imagemPadraoFilal from '../../imagens/padrao_filial.jpg';
import { Link } from 'react-router-dom';

function Unidades() {
  const [unidades, setUnidades] = useState([]);

  useEffect(() => {
    carregaUnidades();
  }, []);

  async function carregaUnidades() {
    const response = await BuscaFiliaisApi();
    setUnidades(response);
  }

  return (
    <>
      <section id="team" className="section">
        <div className="container">
          <div className="section-header">
            <h2 className="section-title">Nossas Unidades</h2>
            <hr className="lines" />
            <p className="section-subtitle">Unidades em todo o Brasil.</p>
          </div>
          <div className="flex">
            {unidades.map((unidade) => {
              const imagemURL = unidade.nomeImg
                ? `/assets/images/filiais/${unidade.nomeImg}`
                : imagemPadraoFilal;
              const linkCoordenadas = unidade.linkCoordenadas
                ? `https://www.google.com/maps/search/${unidade.linkCoordenadas}`
                : '/';
              const linkTelefone = `tel:${unidade.telefone}`;
              return (
                <div key={unidade.IDfilial} className="single-team">
                  <div className="img-container">
                    <img className="imgCardFilial" src={imagemURL} alt="" />
                  </div>
                  <div className="team-details">
                    <div className="team-inner">
                      <h4 className="team-title">{unidade.nome}</h4>
                      <p>{unidade.endereco}</p>
                      <ul className="social-list">
                        <li className="facebook">
                          <a href={unidade.linkFacebook}>
                            <Icons icon="facebook" />
                          </a>
                        </li>
                        <li className="instagram">
                          <a href={unidade.linkInstagram}>
                            <Icons icon="instagram" />
                          </a>
                        </li>
                        <li className="maps">
                          <Link
                            to={linkCoordenadas}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icons icon="mapMarker" />
                          </Link>
                        </li>
                        <li className="phone">
                          {/* <a href='tel:'{unidade.telefone}> */}
                          {/* <a href={unidade.telefone}> */}
                          <Link
                            to={linkTelefone}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icons icon="phone" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
}

export default Unidades;
