import React from "react";
import {RiChatDeleteFill} from "react-icons/ri"

import './CarrinhoItem.css';
import formatCurrency from "../../import/formatCurrency";
import { useCartContext } from "../../../context/CarrinhoContext";

import imgGripe from "../../../imagens/Gripe.png";

function CarrinhoItem({data}){
    
    let {IDprodutos, nome, valor, quantidade} = data;

    const {cartItems, setCartItems, setCartVisible} = useCartContext();

    const handleRemoveItem = () => {
        const updatedItems = cartItems.filter((item) => item.IDprodutos !== IDprodutos);        
        setCartItems(updatedItems);
        if (updatedItems.length === 0) setCartVisible(false);        
    };    

    const Increment = (data) => {
        if (data.quantidade < 99) {
            setCartItems((prevItems) => {            
                const newItems = prevItems.map((item) => 
                    item.IDprodutos === data.IDprodutos ? { ...item, quantidade: item.quantidade + 1 } : item
                );
                return newItems;            
            });
        }
    };
    const Decrement = (data) => {        
        if (data.quantidade > 1) {
            setCartItems((prevItems) => {            
                const newItems = prevItems.map((item) => 
                    item.IDprodutos === data.IDprodutos ? { ...item, quantidade: quantidade - 1 } : item
                );
                return newItems;            
            });
        }
    };

    return(        
        <section className="cart-item">
            <img src={imgGripe} alt="imagem do produto" className="cart-item-image" />
            <div className="cart-item-content">
                <h3 className="cart-item-title">{nome}</h3>
                <div className="cart-item-info">
                    <div className="cart-item-infoQtd">
                        <button type="button" className="cart-item-button-incDecreQtd" onClick={() => Decrement(data)}>
                            -
                        </button>
                        <input type="text" className="cart-item-inputQtd" value={quantidade} maxLength="99"></input>
                        <button type="button" className="cart-item-button-incDecreQtd" onClick={() => Increment(data)}>
                            +
                        </button>
                    </div>
                    {valor === 0 
                    ? <span className="cart-item-price">À consultar</span>
                    : <span className="cart-item-price">{formatCurrency((valor * quantidade), 'BRL' )}</span>   }
                    <span className="cart-item-price">{formatCurrency((valor * quantidade), 'BRL' )}</span>
                </div>
                <button type="button" className="button__remove-item" onClick={() => handleRemoveItem()}>
                    <RiChatDeleteFill />
                </button>
            </div>
        </section>
    )
}

export default CarrinhoItem;