import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Routes from './routes';

import { PrevimuneProvider } from './context/PrevimuneContext';

export default function App() {
  return (
    <PrevimuneProvider>
      <Routes />
    </PrevimuneProvider>
  );
}
