import React, { useState, useEffect } from 'react';
import HeaderSite from '../../components/layout/HeaderMenu';
import { api } from '../../services/api.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useListContext } from '../../context/ListContext.jsx';
import { useAuthContext } from '../../context/AuthContext.jsx';
import sweetAlert from '../../components/layout/sweetAlert.js';
import BuscaFiliaisApi from '../../api/buscaFiliaisApi';
import './style.css';

const LancAtivos = () => {
  const [titulotela, settitulotela] = useState('');
  const [mesatual, setmesatual] = useState('0000/00');
  const [mes_select, setmes] = useState('');
  const { payload } = useAuthContext();
  const [idfilialsel, setIDfilial] = useState(0);
  const [filiais, setFiliais] = useState([]);
  const [cidadeFilial, setCidadeFilial] = useState('');
  const [ufFilial, setUFFilial] = useState('');
  const [selectedFilial, setSelectedFilial] = useState(-1);
  const [ListAtivos, setListAtivos] = useState([]);

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const [idativo, setidativo] = useState('');
  const [idativolanc, setidativolanc] = useState('');
  const [valor, setvalor] = useState('0.00');

  const [totalEntrada, settotalEntrada] = useState('0.00');
  const [totalSaida, settotalSaida] = useState('0.00');
  const [totalSaldo, settotalSaldo] = useState('0.00');

  const IDusuario = payload.user.IDusuario;
  const IDfilial = payload.user.IDfilial;

  useEffect(function () {
    console.log('IDfilial', IDfilial);
    setIDfilial(IDfilial);
    if (IDfilial != 1) setSelectedFilial(IDfilial);
    setCidadeFilial(payload.user.cidade);
    setUFFilial(payload.user.uf);

    BuscaMes(IDfilial);
    carregaUnidades();
  }, []);

  const handleCheckboxChange = (selectedOptions) => {
    //console.log('Opções selecionadas:', selectedOptions);
  };

  async function carregaUnidades() {
    const response = await BuscaFiliaisApi();

    if (!!response) {
      const filiaisOrdenadas = [...response].sort((a, b) =>
        a.cidade.localeCompare(b.cidade)
      );
      setFiliais(filiaisOrdenadas);
    }
  }

  async function BuscaMes(IDfilial) {
    // try {
    //   await BuscaToken();
    // } catch (error) {
    //   console.log('Erro ao consultar token', error);
    // }

    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    let retorno = '';

    if (retorno.length > 0) {
    } else {
      const dataAtual = new Date();
      const mes = meses[dataAtual.getMonth()];
      const ano = dataAtual.getFullYear();
      setmesatual(`${ano}-${dataAtual.getMonth() + 1}`);
      settitulotela(`${mes} de ${ano}`);
      setmes(mes);

      if (IDfilial != 1) {
        await CarregaAtivos(IDfilial, mes);
      }
    }
  }

  async function AnteriorMes() {
    var mesAtual_ = mesatual;
    const partes = mesAtual_.split('-');
    let ano = parseInt(partes[0]);
    let mes = parseInt(partes[1]);
    // Adiciona 1 ao mês
    mes = mes - 1;

    // Verifica se o mês ultrapassou 12, se sim, incrementa o ano e define o mês como 1
    if (mes == 0) {
      mes = 12;
      ano = ano - 1;
    }

    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    // Formata o resultado para 'yyyy-MM'
    setmesatual(`${ano}-${mes.toString().padStart(2, '0')}`);
    await setmes(meses[mes - 1]);
    await settitulotela(`${meses[mes - 1]} de ${ano}`);

    if (IDfilial > 0) {
      await CarregaAtivos(selectedFilial, meses[mes - 1]);
    }
  }

  async function ProximoMes() {
    var mesAtual_ = mesatual;
    const partes = mesAtual_.split('-');
    let ano = parseInt(partes[0]);
    let mes = parseInt(partes[1]);

    // Adiciona 1 ao mês
    mes++;

    // Verifica se o mês ultrapassou 12, se sim, incrementa o ano e define o mês como 1
    if (mes > 12) {
      mes = 1;
      ano++;
    }

    const meses = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro',
    ];

    // Formata o resultado para 'yyyy-MM'
    await setmesatual(`${ano}-${mes.toString().padStart(2, '0')}`);
    await setmes(meses[mes - 1]);
    settitulotela(`${meses[mes - 1]} de ${ano}`);

    if (IDfilial > 0) {
      await CarregaAtivos(selectedFilial, meses[mes - 1]);
    }
  }

  function isValidDate(dateString) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
      return false;
    }
    const date = new Date(dateString);
    const isValid = !isNaN(date.getTime());
    return isValid;
  }

  async function LimparTela() {
    setListAtivos([]);
    setSelectedFilial(-1);
    settotalEntrada(0.0);
    settotalSaida(0.0);
    settotalSaldo(0.0);
  }

  async function SalvarValoresLencamento() {
    ListAtivos.forEach(async (dados) => {
      const valorDigitado = parseFloat(dados.valor);
      if (!isNaN(valorDigitado)) {
        let vIDlancamentos_ativos = dados.IDlancamentos_ativos;
        let vIDativo = dados.idativo;
        let vValor = valorDigitado;

        if (vIDlancamentos_ativos > 0) {
          await AlterarLanc(
            vIDlancamentos_ativos,
            vIDativo,
            vValor,
            dados.ativo,
            dados.tipo
          );
        } else {
          await InsertLanc(vIDativo, vValor, dados.ativo, dados.tipo);
        }
        CarregaTotais(selectedFilial, mes_select);
      }
    });
  }

  async function AlterarLanc(
    IDlancamentos_ativos,
    IDativo,
    vValor,
    nome,
    tipo
  ) {
    const dados = {
      IDativo: IDativo,
      ativo: nome,
      tipo: tipo,
      valor: vValor,
      mes: mes_select,
      data: new Date(),
      IDfilial: selectedFilial,
      IDlancamentos_ativos: IDlancamentos_ativos,
    };

    try {
      return await api
        .put('/LancamentoAtivoFilialMes', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function InsertLanc(IDativo, vValor, nome, tipo) {
    const dados = {
      IDativo: IDativo,
      ativo: nome,
      tipo: tipo,
      valor: vValor,
      mes: mes_select,
      data: new Date(),
      IDfilial: selectedFilial,
    };

    try {
      return await api
        .post('/LancamentoAtivoFilialMes', dados)
        .then(function (response) {
          console.log(response.data);
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  const handleSelectChange = async (event) => {
    const valorSelecionado = parseInt(event.target.value);
    setSelectedFilial(valorSelecionado);
    if (valorSelecionado == 0) {
      setSelectedFilial(-1);
    }
    await CarregaAtivos(event.target.value, mes_select);
  };

  async function CarregaAtivos(IDfilial, mes) {
    let ativos = [];

    console.log('busca em filial: ', IDfilial);

    if (IDfilial > 0) {
      let retorno = await BuscaAtivos(IDfilial, mes);

      for (const element of retorno) {
        ativos.push({
          idativo: element.IDativo,
          ativo: element.nome,
          tipo: element.tipo === 'S' ? 'SAÍDA' : 'ENTRADA',
          IDlancamentos_ativos: element.IDlancamentos_ativos,
          valor: DuasCasasDecimal(element.valor),
        });
      }
    }
    setListAtivos(ativos);

    await CarregaTotais(IDfilial, mes);
  }

  async function CarregaTotais(IDfilial, mes) {
    let retorno = await BuscaTotal(IDfilial, mes);

    retorno.forEach((element) => {
      settotalEntrada(element.entrada);
      settotalSaida(element.saida);
      settotalSaldo(element.saldo);
    });
  }

  async function BuscaTotal(IDfilial, mes) {
    return await api
      .get('/LancamentoAtivoFilialMes_totais/' + IDfilial + '/' + mes)
      .then(function (response) {
        console.log('rotoenoo+++> ' + response.data[0]);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaAtivos(IDfilial, mes) {
    return await api
      .get('/LancamentoAtivoFilialMesValores/' + IDfilial + '/' + mes)
      .then(function (response) {
        console.log('rotoenoo+++> ' + response.data[0]);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  function ExibeFilial() {
    if (payload.user.IDusuario !== 1) {
      return (
        <input
          id="filialAtivo"
          className="width_cemporcento form-control form_lancamento"
          type="text"
          value={cidadeFilial + ' - ' + ufFilial}
          disabled={true}
        />
      );
    } else {
      return (
        <select
          onChange={(e) => handleSelectChange(e)}
          style={{
            borderRadius: '5px',
            width: '100%',
            height: '30px',
            padding: '2px',
          }}
          id="dropdown"
          value={selectedFilial}
        >
          <option key="-1" value="-1">
            -- SELECIONE UMA OPÇÃO --
          </option>
          {filiais.length > 0 &&
            filiais.map((filial) => (
              <option key={filial.IDfilial} value={filial.IDfilial}>
                {filial.cidade} - {filial.uf}
              </option>
            ))}
        </select>
      );
    }
  }

  const handleInputChange = (id, value) => {
    setListAtivos((prevList) =>
      prevList.map((item) =>
        item.idativo === id ? { ...item, valor: value } : item
      )
    );
  };

  function DuasCasasDecimal(valor) {
    var valor1 = valor;
    return (Math.round(valor1 * 100) / 100).toFixed(2);
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        padding: '2%',
        width: '100%',
        height: '100vh',
      }}
    >
      <div className="row" style={{ backgroundColor: 'rgb(243, 240, 231)' }}>
        <HeaderSite></HeaderSite>

        <div
          className="col-sm-9"
          style={{ padding: '1em', backgroundColor: 'white' }}
        >
          <div className="row">
            <div className="col-sm-10">
              <p id="titulopage"> {titulotela}</p>
            </div>

            <div className="col-sm-2" style={{ alignItems: 'rigth' }}>
              <input
                className="btn btn-info"
                type="button"
                value="<"
                onClick={() => AnteriorMes()}
              />
              <input
                className="btn btn-info"
                type="button"
                style={{ marginLeft: '5px' }}
                value=">"
                onClick={() => ProximoMes()}
              />
            </div>
          </div>
          <br />

          <div style={{ width: '100%' }}>
            <label>Franquia:</label>
            <br />
            <ExibeFilial></ExibeFilial>
            <br />
            <br />
          </div>

          {ListAtivos.map((dados) => (
            <div className="row" key={dados.idativo}>
              <div className="col-sm-7">
                <input
                  id={dados.IDlancamentos_ativos}
                  style={{ display: 'none' }}
                  type="text"
                  value={dados.IDlancamentos_ativos}
                  onChange={(e) => setidativo(e.target.value)}
                />

                <label>Ativo:</label>
                <br />
                <input
                  id={dados.ativo}
                  className="width_cemporcento  form-control form_lancamento"
                  type="text"
                  value={'(' + dados.tipo + ') ' + dados.ativo}
                  readOnly={true}
                  onChange={(e) => {}}
                />
              </div>

              <div className="col-sm-5">
                <label>Valor:</label>
                <br />
                <input
                  id={dados.idativo}
                  className="width_cemporcento form-control form_lancamento"
                  type="number"
                  min={0}
                  value={dados.valor}
                  onChange={(e) => {
                    const cleanedValue = e.target.value.replace(/[^0-9.]/g, ''); // Remove caracteres não numéricos e não pontos
                    handleInputChange(dados.idativo, cleanedValue);
                  }}
                />
              </div>
            </div>
          ))}

          <br />
          <br />
          <div className="row">
            <div className="col-sm-4">
              <p style={{ color: 'green', textAlign: 'center' }}>
                TOTAL ENTRADAS: R$ {DuasCasasDecimal(totalEntrada)}
              </p>
            </div>
            <div className="col-sm-4">
              <p style={{ color: 'red', textAlign: 'center' }}>
                TOTAL SAÍDAS: R$ {DuasCasasDecimal(totalSaida)}
              </p>
            </div>
            <div className="col-sm-4">
              <p style={{ color: 'blue', textAlign: 'center' }}>
                SALDO: R$ {DuasCasasDecimal(totalSaldo)}
              </p>
            </div>
          </div>
          <br />
          <br />

          <div className="row">
            <div className="col-sm-2">
              <input
                className={TipoBotao}
                type="button"
                value={TextBotao}
                style={{ color: 'black' }}
                onClick={() => SalvarValoresLencamento()}
              />
            </div>
            <div className="col-sm-2">
              <input
                className="btn btn-light"
                type="button"
                value="Limpar"
                style={{ color: 'black', borderColor: '#d3d3d3' }}
                onClick={() => LimparTela()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LancAtivos;
