import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Carrinho.css';
import CarrinhoItem from '../carrinhoItem/CarrinhoItem';
import { useCartContext } from '../../../context/CarrinhoContext';
import formatCurrency from '../../import/formatCurrency';
import BuscaFiliaisApi from '../../../api/buscaFiliaisApi';

import { BsWhatsapp } from 'react-icons/bs';
import { BsCart2 } from 'react-icons/bs';

function Carrinho() {
  const navigate = useNavigate();
  const { cartItems, isCartVisible } = useCartContext();

  const idFilialLogada = localStorage.getItem('filial');

  const valorTotal = cartItems.reduce(
    (acc, item) => item.valor * item.quantidade + acc,
    0
  );

  async function gotoWhatsappVenda() {
    //Numero de Whatsapp para contato
    //var number = '+5535988494588';

    const response = await BuscaFiliaisApi(idFilialLogada);

    if (!response) {
      return false;
    } else {
      let number = response[0].telefone;

      if (number.length === 0) {
        return false;
      } else {
        let url =
          `https://api.whatsapp.com/send?phone=` +
          number +
          `&text=` +
          `*LISTA DE COMPRAS:* %0a` +
          `-----------------------------%0a%0a`;

        cartItems.forEach((cartItem) => {
          url +=
            `*Produto:* ${cartItem.nome}%0a` +
            `*Quantidade:* ${cartItem.quantidade}%0a%0a`;
        });

        url += `-----------------------------%0a%0a`;
        url +=
          valorTotal > 0
            ? `*Total*: ${formatCurrency(valorTotal, 'BRL')}`
            : '*Total*: À consultar';

        window.open(url, '_blank').focus();
      }
    }
  }

  return (
    <section className={`cart ${isCartVisible ? `cart-active` : ``}`}>
      <div className="cart-title">ITENS NO CARRINHO: {cartItems.length}</div>
      <div className="cart-items">
        {cartItems.map((cartItem) => (
          <CarrinhoItem key={cartItem.Id} data={cartItem} />
        ))}
      </div>
      {cartItems.length === 0 && (
        <div className="cart-body" id="semProdutos">
          <div className="carrinhoVazio">
            <div className="carrinhoVazio-icon">
              <BsCart2 />
            </div>
            <span className="carrinhoVazio-title">Seu carrinho está vazio</span>
            <span className="carrinhoVazio-subtitle">
              Navegue pelo nosso catálogo de vacinas e escolha a(s) vacina(s)
              desejada(s) para adicionar ao seu carrinho de compras
            </span>
          </div>
        </div>
      )}
      <div className="bottomCart">
        <div className="cart-resume">
          Resumo:<br></br>
          {formatCurrency(valorTotal, 'BRL')}
        </div>
        <button
          className="btn btn-common btnFecharPedido"
          id="button"
          type="button"
          //   onClick={() => {
          //     navigate('/Vacinas');
          //   }}
          onClick={gotoWhatsappVenda}
        >
          <BsWhatsapp style={{ marginRight: '4px' }} />
          Fechar Pedido
        </button>
      </div>
    </section>
  );
}

export default Carrinho;
