import React from "react";

import { useCartContext } from "../../../context/CarrinhoContext";

import { BsCart4 } from "react-icons/bs";

import './CarrinhoBotao.css'


function CarrinhoBotao({legenda}) {
    
    const {cartItems, isCartVisible, setCartVisible} = useCartContext();    

    return(
        cartItems.length > 0 &&
        <button 
            type="button" 
            className="carrinhoPedido"
            //className={`carrinhoPedido ${isVisible ? `carrinhoPedido-visible`:''}`} 
            onClick={() => setCartVisible(!isCartVisible)}
            align="center" 
        >                    
            <BsCart4 />
            <span className="legenda">{legenda}</span>
            {cartItems.length > 0 && <span className="cart-status">{cartItems.length}</span>}
        </button>
    );
}

export default CarrinhoBotao;