import React from "react";
import HeaderSite from "../../components/layout/HeaderSite";
import Contact from "../../components/layout/Contact";
import Footer from "../../components/layout/Footer";
import GoToTop from "../../components/layout/GoToTop";

import "../../main.css";

import prematuro from "../../imagens/img/fotos/fotos/prematuro.jpg";
import crianca from "../../imagens/img/fotos/fotos/criancas.jpg";
import adolescente from "../../imagens/img/fotos/fotos/adolescente.jpg";
import adulto from "../../imagens/img/fotos/fotos/adultos.jpg";
import idoso from "../../imagens/img/fotos/fotos/idosos.jpg";

import calendarioPrematuro from "../../uploads/clinivac-calendario-vacinacao-sbim-prematuro.pdf";
import calendarioCrianca from "../../uploads/clinivac-calendario-vacinacao-sbim-crianca.pdf";
import calendarioAdolescente from "../../uploads/clinivac-calendario-vacinacao-sbim-adolescente.pdf";
import calendarioAdulto from "../../uploads/clinivac-calendario-vacinacao-sbim-adulto.pdf";
import calendarioIdoso from "../../uploads/clinivac-calendario-vacinacao-sbim-idoso.pdf";

const Calendario = () => {
  return (
    <>
      <HeaderSite></HeaderSite>
      <section id="calendario" className="section calendario-section">
        <div className="container">
          <div className="section-header">
            <h2
              className="section-title wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
            >
              Calendário de Vacinas
            </h2>
            <hr className="lines wow zoomIn" data-wow-delay="0.3s" />
            <p
              className="section-subtitle wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
            >
              Vejas as Vacinas para cada fase da vida e proteja sua família!
            </p>
            <p
              className="section-subtitle wow fadeIn"
              data-wow-duration="1000ms"
              data-wow-delay="0.3s"
            >
              Para proteger nossa saúde, as vacinas precisam estimular o sistema
              imunológico - também chamado de sistema imunitário ou imune -{" "}
              <br />a produzir anticorpos, um tipo de proteína, agentes de
              defesa que atuam contra os micróbios que provocam doenças
              infecciosas.
            </p>
          </div>
          <div className="section_wrapper">
            <div className="wrap">
              <div className="column">
                <div>
                  <img src={prematuro} alt="prematuro" />
                  <div className="dados">
                    <h3>Prematuro - 1º ano</h3>
                    <p>
                      Veja as vacinas para bebês.
                      <br />
                      As proteções essenciais para os pequenos.
                      <br />
                      Recomendações da Sociedade Brasileira de Imunizações
                      (SBIM)
                    </p>
                    <div className="calendario-button">
                      <a
                        href={calendarioPrematuro}
                        target="blank"
                        data-uw-styling-context="true"
                      >
                        <button
                          className="btn btn-common"
                          id="button"
                          type="button"
                        >
                          Calendário Bebês
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div>
                  <div className="dados">
                    <h3>Crianças</h3>
                    <p>
                      Veja as vacinas essenciais para proteção dos pequenos.
                      <br />
                      Recomendações da Sociedade Brasileira de Imunizações
                      (SBIM)
                    </p>
                    <div className="calendario-button">
                      <a
                        href={calendarioCrianca}
                        target="blank"
                        data-uw-styling-context="true"
                      >
                        <button
                          className="btn btn-common"
                          id="button"
                          type="button"
                        >
                          Calendário Crianças
                        </button>
                      </a>
                    </div>
                  </div>
                  <img src={crianca} alt="criança" />
                </div>
              </div>
              <div className="column">
                <div>
                  <img src={adolescente} alt="adolescente" />
                  <div className="dados">
                    <h3>Adolescentes</h3>
                    <p>
                      Veja as vacinas essenciais para a proteção dos
                      adolescentes.
                      <br />
                      Recomendações da Sociedade Brasileira de Imunizações
                      (SBIM)
                    </p>
                    <div className="calendario-button">
                      <a
                        href={calendarioAdolescente}
                        target="blank"
                        data-uw-styling-context="true"
                      >
                        <button
                          className="btn btn-common"
                          id="button"
                          type="button"
                        >
                          Calendário Adolescentes
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="column">
                <div>
                  <div className="dados">
                    <h3>Adultos</h3>
                    <p>
                      Veja as vacinas essenciais para proteção dos adultos na
                      correria do dia a dia.
                      <br />
                      Recomendações da Sociedade Brasileira de Imunizações
                      (SBIM)
                    </p>
                    <div className="calendario-button">
                      <a
                        href={calendarioAdulto}
                        target="blank"
                        data-uw-styling-context="true"
                      >
                        <button
                          className="btn btn-common"
                          id="button"
                          type="button"
                        >
                          Calendário Adultos
                        </button>
                      </a>
                    </div>
                  </div>
                  <img src={adulto} alt="adulto" />
                </div>
              </div>
              <div className="column">
                <div>
                  <img src={idoso} alt="idoso" />
                  <div className="dados">
                    <h3>Idosos</h3>
                    <p>
                      Veja as vacinas essenciais para proteção dos idosos.
                      <br />
                      Recomendações da Sociedade Brasileira de Imunizações
                      (SBIM)
                    </p>
                    <div className="calendario-button">
                      <a
                        href={calendarioIdoso}
                        target="blank"
                        data-uw-styling-context="true"
                      >
                        <button
                          className="btn btn-common"
                          id="button"
                          type="button"
                        >
                          Calendário Idosos
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contact></Contact>
      <Footer></Footer>
      <GoToTop></GoToTop>
    </>
  );
};

export default Calendario;
