import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

//import logo from "../../imagens/img/logo.png";
import logo from '../../imagens/img/logos/logo_original.png';
import logo_branco from '../../imagens/img/logos/logo_branco.png';

import '../../main.css';
import CarrinhoBotao from './carrinhoBotao/CarrinhoBotao';

const navItemStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};

const Navbar = () => {
  //altera cor de fundo ao rolar tela
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const header = document.querySelector('.navbar');
    const headerTop = header.getBoundingClientRect().top;

    const handleScroll = () => {
      setIsSticky(window.scrollY > headerTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav
      className={`navbar navbar-expand-lg scrolling-navbar indigo justify-content-center navbar-bgTransparente ${
        isSticky ? `top-nav-collapse` : ''
      }`}
      style={{
        position: 'fixed',
        top: '0',
        right: '0',
        left: '0',
        zIndex: '5',
      }}
    >
      <div className="navbar-header d-flex justify-content-start">
        <Link to="/">
          <div className="navbar-brand navbar__logo">
            <img
              id="imgLogoSite"
              src={isSticky ? logo : logo_branco}
              alt="Logo"
            />
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#main-navbar"
              aria-controls="main-navbar"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <i className="lnr lnr-menu"></i> */}
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </Link>
      </div>
      <div className="menuMargem ">
        <div className="collapse navbar-collapse" id="main-navbar">
          <ul className="navbar-nav mr-auto w-100 justify-content-end">
            <li className="nav-item nav-link page-scroll">
              <a className="is-active" href="/Home" exact="true">
                Início
              </a>
            </li>
            <li className="nav-item nav-link page-scroll">
              <a href="/Home/#services">Destaques</a>
            </li>
            <li className="nav-item nav-link page-scroll">
              <a href="/Home/#features">Sobre</a>
              {/*<RouterLink to="/Sobre">Sobre</RouterLink>*/}
            </li>
            <li className="nav-item nav-link page-scroll">
              {/*<NavLink>Vacinas</NavLink>*/}
              <a href="/vacinas">Vacinas</a>
            </li>
            <li className="nav-item nav-link page-scroll">
              <a href="/franqueado">Franqueado</a>
            </li>
            <li className="nav-item nav-link page-scroll">
              <a href="/Home/#team">Unidades</a>
              {/*<NavLink>Unidades</NavLink>*/}
            </li>
            <li className="nav-item nav-link page-scroll">
              <a href="/Home/#contact">Contato</a>
              {/*<NavLink>Contato</NavLink>*/}
            </li>
            <li className="nav-item nav-link page-scroll">
              <a href="/Login">Administrativo</a>
            </li>
          </ul>
        </div>
      </div>
      <Link
        className="linkCarrinhoMenu cursorPonteiro"
        style={{ color: '#fff', textDecoration: 'none' }}
      >
        {/* <div className="compras compras-header">
          <img className="carrinho" src={carrinho} alt="carrinhoCompras"></img>
          <ol>
            <li>Carrinho</li>            
          </ol>
        </div> */}
        {/*
        <button type="button" className="carrinhoPedido" align="center">
          <BsCart4 />
          <span className="legenda">Carrinho</span>
          <span className="cart-status">2</span>
        </button>
      */}
        <CarrinhoBotao legenda={'Carrinho'} />
      </Link>

      {/*<!-- Mobile Menu Start -->*/}
      <ul className="mobile-menu" id="mobile-menu">
        <li>
          <Link className="page-scroll" to="#hero-area">
            Início
          </Link>
        </li>
        <li>
          <Link className="page-scroll" to="#services">
            Destaques
          </Link>
        </li>
        <li>
          <Link className="page-scroll" to="#features">
            Sobre
          </Link>
        </li>
        <li className="page-scroll">
          <Link className="nav-link page-scroll cursorPonteiro" to="/vacinas">
            Vacinas
          </Link>
        </li>
        <li>
          <Link className="page-scroll" to="#franqueado_home">
            Franqueado
          </Link>
        </li>
        <li>
          <Link className="page-scroll" to="#team">
            Unidades
          </Link>
        </li>
        <li>
          <Link className="page-scroll" to="#contact">
            Contatos
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className="page-scroll"
            style={navItemStyle}
            data-toggle="modal"
            data-target="#exampleModal"
          >
            Área Administrativa
          </Link>
        </li>
      </ul>
      {/*<!-- Mobile Menu End -->*/}
    </nav>
  );
};

export default Navbar;
