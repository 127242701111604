import React, { createContext, useContext, useState} from "react";

const PrevimuneContext = createContext();

const PrevimuneProvider = ({children}) => {
        
    const [filiais, setFiliais] = useState([]);
    const [selectedFilial, setSelectedFilial] = useState(0);    
      
    const value ={
        filiais,
        setFiliais,        
        selectedFilial,
        setSelectedFilial,        
    }

    return(
        <PrevimuneContext.Provider value= {value}>
            {children}
        </PrevimuneContext.Provider>
    );
}

const usePrevimuneContext = () => useContext(PrevimuneContext);

export {PrevimuneProvider, usePrevimuneContext};