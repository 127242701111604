import React, { useState, useEffect } from 'react';
import { api } from '../../services/api';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';
import { ListItens } from '../../components/layout/ListItens';
import { useListContext } from '../../context/ListContext.jsx';
import CheckboxList from '../../components/layout/checkboxList';
import { BuscaToken } from '../../api/consultaApi';
import sweetAlert from '../../components/layout/sweetAlert.js';

import BuscaFiliaisApi from '../../api/buscaFiliaisApi';

const CadUsuarios = () => {
  const [idusuario, setIDusuario] = useState(0);
  const [idfilial, setIDfilial] = useState(0);
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [login, setLogin] = useState('');
  const [senha, setSenha] = useState('');
  const [cidadeFilial, setCidadeFilial] = useState('');
  const [ufFilial, setUFFilial] = useState('');
  const [dadosGridUsuarios, setdadosGridUsuarios] = useState([]);
  const [listaCargos, setListaCargos] = useState([]);

  const [filiais, setFiliais] = useState([]);
  const [selectedFilial, setSelectedFilial] = useState(-1);

  const [TextBotao, setTextBotao] = useState('Salvar');
  const [TipoBotao, setTipoBotao] = useState('btn btn-success');

  const { Opreracao, setOpreracao, selectedOptions, setSelectedOptions } =
    useListContext();

  useEffect(function () {
    carregaGridUsuarios();
    carregaListCargos();
    if (filiais.length === 0) {
      carregaUnidades();
    }
  }, []);

  useEffect(
    function () {
      VerificaOperacao();
    },
    [Opreracao]
  );

  const handleCheckboxChange = (selectedOptions) => {
    //console.log('Opções selecionadas:', selectedOptions);
  };

  async function carregaUnidades() {
    const response = await BuscaFiliaisApi();
    const filiaisOrdenadas = [...response].sort((a, b) =>
      a.cidade.localeCompare(b.cidade)
    );
    setFiliais(filiaisOrdenadas);
  }

  async function VerificaOperacao() {
    let ok;
    let dados = Opreracao;
    if (dados !== '') {
      ok = await CarregaDados(dados);
      setOpreracao('');
    }
  }

  async function CarregaDados(Objeto) {
    let retorno;
    try {
      //await BuscaToken();
    } catch (error) {
      console.log('Erro ao consultar token', error);
    }

    retorno = await BuscaUsuarioID(Objeto.Id);

    setIDusuario(retorno[0].IDusuario);
    setIDfilial(retorno[0].IDfilial);
    setNome(retorno[0].nome);
    setEmail(retorno[0].email);
    setLogin(retorno[0].login);
    setCidadeFilial(retorno[0].cidade);
    setUFFilial(retorno[0].uf);

    let dados = [];
    retorno = await BuscaCargosIDUsuario(Objeto.Id);
    retorno.map(function (l) {
      dados.push(l.IDcargo);
    });

    setSelectedOptions(dados);

    if (Objeto.Operacao === 'Deletar') {
      setTextBotao('Deletar');
      setTipoBotao('btn btn-danger');
    } else {
      setTextBotao('Alterar');
      setTipoBotao('btn btn-secondary');
    }
  }

  async function carregaListCargos() {
    let Cargos = [];

    let retorno = await BuscaCargos();
    retorno.forEach((element) => {
      Cargos.push({
        id: element.IDcargo,
        titulo:
          element.nome.charAt(0).toUpperCase() + element.nome.substring(1),
        //DEIXAR PRIMEIRA LETRA MAIUSCULA
        //string.charAt(0).toUpperCase() + string.substring(1);
      });
    });

    setListaCargos(Cargos);
  }

  async function BuscaUsuarioID(Id) {
    return await api
      .get('/UsuarioID/' + Id)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaCargos() {
    return await api
      .get('/Cargos')
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaCargosIDUsuario(IDusuario) {
    return await api
      .get('/UsuarioCargoIDUsuario/' + IDusuario)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeleteUsuario() {
    return await api
      .delete('/Usuario/' + idusuario)
      .then(function (response) {
        //console.log(response);
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function BuscaUsuarios() {
    return await api
      .get('/Usuarios')
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function SalvaAlteraUsuario() {
    let retorno;
    if (TextBotao === 'Deletar') {
      if (
        window.confirm(
          'Essa opção vai DELETAR definitivamente esse cadastro. \n\nClique OK para Prosseguir.'
        )
      ) {
        await DeleteUsuario();
        carregaGridUsuarios();
        LimparTela();
      }
    } else {
      if (selectedOptions.length > 0) {
        try {
          //await BuscaToken();
        } catch (error) {
          console.log('Erro ao consultar token', error);
        }

        if (idfilial > 0) {
          if (idusuario === 0) {
            retorno = await InserirUsuario();
            //INSERE USUARIOS_CARGOS
            if (retorno.insert === 'OK') {
              await AtualizaCargosUsuario(retorno.IDusuario);
            }
            carregaGridUsuarios();
            LimparTela();
          } else {
            retorno = await AlterarUsuario();
            //ATUALIZA USUARIOS_CARGOS
            if (retorno.update === 'OK') {
              await AtualizaCargosUsuario(idusuario);
            }
            carregaGridUsuarios();
            LimparTela();
          }
        } else {
          //alert('Nenhuma filial selecionada. Não será possível prosseguir!');
          sweetAlert(
            'error',
            'Atenção',
            'Nenhuma franquia selecionada. Não será possível prosseguir!'
          );
        }
      } else {
        //alert('Favor preencher os cargos que esse usuário tem permissão!');
        sweetAlert(
          'warning',
          'Atenção',
          'Favor preencher os cargos que esse usuário tem permissão!'
        );
      }
    }
  }

  async function AtualizaCargosUsuario(IDusuario) {
    let retorno;

    retorno = await DeletaCargosUsuario(IDusuario);
    if (retorno.Delete === 'OK') {
      selectedOptions.map(function (id_) {
        InsertCargosUsuario(IDusuario, id_);
      });
      return { CargosUsuario: 'OK' };
    } else {
      return { Error: 'Atualização não realizada(DELETE)' };
    }
  }

  async function InsertCargosUsuario(IDusuario, IDcargo) {
    let dadosPost = {
      IDusuario: IDusuario,
      IDcargo: IDcargo,
    };

    return await api
      .post('/UsuarioCargo', dadosPost)
      .then(function (response) {
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function DeletaCargosUsuario(IDusuario) {
    return await api
      .delete('/UsuarioCargoIDUsuario/' + IDusuario)
      .then(function (response) {
        //console.log(response);
        return response.data;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
  }

  async function InserirUsuario() {
    const dados = {
      IDfilial: idfilial,
      nome: nome,
      email: email,
      login: login,
      senha: senha,
    };

    try {
      return await api
        .post('/Usuario', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function AlterarUsuario() {
    const dados = {
      IDusuario: idusuario,
      IDfilial: idfilial,
      nome: nome,
      email: email,
      login: login,
    };
    if (!!senha) {
      dados.senha = senha;
    }

    try {
      return await api
        .put('/UsuarioAlterar', dados)
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          return error;
        });
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async function carregaGridUsuarios() {
    var usuarios = [];

    try {
      //await BuscaToken();
    } catch (error) {
      console.log('Erro ao consultar token', error);
    }

    let retorno = await BuscaUsuarios();
    retorno.forEach((element) => {
      usuarios.push({
        id: element.IDusuario,
        coluna2: element.nome,
        coluna3: element.cidade + ' - ' + element.uf,
      });
    });

    setdadosGridUsuarios(usuarios);
  }

  function LimparTela() {
    setIDusuario(0);
    setIDfilial(0);
    setNome('');
    setEmail('');
    setLogin('');
    setSenha('');
    setCidadeFilial('');
    setUFFilial('');
    setSelectedFilial(-1);
    setTextBotao('Salvar');
    setTipoBotao('btn btn-success');
    setSelectedOptions([]);
  }

  const handleSelectChange = (event) => {
    const valorSelecionado = parseInt(event.target.value);
    setSelectedFilial(valorSelecionado);
    if (valorSelecionado > 0) setIDfilial(valorSelecionado);
  };

  function ExibeFilial() {
    if (!!idusuario) {
      return (
        <input
          id="filialusuario"
          className="width_cemporcento form-control"
          type="text"
          value={cidadeFilial + ' - ' + ufFilial}
          disabled={TipoBotao !== 'btn btn-success'}
        />
      );
    } else {
      return (
        <select
          onChange={(e) => handleSelectChange(e)}
          style={{
            borderRadius: '5px',
            width: '100%',
            height: '30px',
            padding: '2px',
          }}
          id="dropdown"
          value={selectedFilial}
        >
          <option key="-1" value="-1">
            -- SELECIONE UMA OPÇÃO --
          </option>
          {filiais.length > 0 &&
            filiais.map((filial) => (
              <option key={filial.IDfilial} value={filial.IDfilial}>
                {filial.cidade} - {filial.uf}
              </option>
            ))}
        </select>
      );
    }
  }

  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        width: '100%',
        height: '100vh',
      }}
    >
      <div
        className="row"
        style={{
          padding: '2%',
          paddingBottom: '0',
          backgroundColor: 'rgb(243, 240, 231)',
        }}
      >
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div id="formulario">
            <form id="formulario" autoComplete="off">
              <fieldset>
                <legend>CADASTRO - USUÁRIOS</legend>
                <input
                  id="idusuario"
                  style={{ display: 'none' }}
                  type="text"
                  value={idusuario}
                  onChange={(e) => setIDusuario(e.target.value)}
                />
                <br />
                <br />
                <input
                  id="idfilialusuario"
                  style={{ display: 'none' }}
                  type="text"
                  value={idfilial}
                  onChange={(e) => setIDfilial(e.target.value)}
                />
                <label>Nome:</label>
                <br />
                <input
                  id="nomeusuario"
                  className="width_cemporcento  form-control"
                  type="text"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
                <br />
                <br />
                <label>Franquia:</label>
                <br />
                <ExibeFilial></ExibeFilial>
                <br />
                <br />
                <label>Email:</label>
                <br />
                <input
                  id="emailusuario"
                  className="width_cemporcento form-control"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <br />
                <br />
                <label>Login:</label>
                <br />
                <input
                  id="loginusuario"
                  className="width_cemporcento form-control"
                  type="text"
                  value={login}
                  onChange={(e) => setLogin(e.target.value)}
                />
                <br />
                <br />
                <label>Senha:</label>
                <br />
                <input
                  id="senhausuario"
                  className="width_cemporcento form-control"
                  type="text"
                  value={senha}
                  onChange={(e) => setSenha(e.target.value)}
                />
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-6" style={{ paddingLeft: '15px' }}>
                    <CheckboxList
                      options={listaCargos}
                      onCheckboxChange={handleCheckboxChange}
                      titulo={'LISTA DE CARGOS'}
                    />
                  </div>
                </div>
                <br />
                <br />
                <div className="row">
                  <div className="col-sm-2">
                    <input
                      className={TipoBotao}
                      type="button"
                      value={TextBotao}
                      onClick={() => SalvaAlteraUsuario()}
                    />
                  </div>
                  <div className="col-sm-2">
                    <input
                      className="btn btn-light"
                      type="button"
                      value="Limpar"
                      onClick={() => LimparTela()}
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <ListItens
        titulogeral={'USUÁRIOS CADASTRADOS'}
        nomeColuna1={'ID'}
        nomeColuna2={'NOME'}
        nomeColuna3={'FRANQUIA'}
        dadosGrid={dadosGridUsuarios}
        tipo={'3'}
      />
    </div>
  );
};

export default CadUsuarios;
