import React, { createContext, useContext, useState } from "react";

const ListContext = createContext();

const ListProvider = ({children}) => {
        
    const [Opreracao, setOpreracao] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);

    return(
        <ListContext.Provider value= {{
          Opreracao,
          setOpreracao,
          selectedOptions,
          setSelectedOptions}}>
            {children}
        </ListContext.Provider>
    );
}

const useListContext = () => useContext(ListContext);

export {ListProvider, useListContext};