import React from 'react';
import '../../Pages/site.css';
import '../../main.css';
import Navbar from '../../components/layout/Navbar';
// import banner from "../../imagens/img/fotos/banners/bannerHome.jpg";
import banner from '../../imagens/img/fotos/banners/bannerHome_novo.jpg';

function HeaderSite() {
  return (
    <header id="hero-area" data-stellar-background-ratio="0.5">
      <div className="banner" align="center">
        <img style={{ width: '100vw' }} src={banner} alt="banner"></img>
      </div>
      <div
        className="col-lg-11"
        style={{
          position: 'fixed',
          top: '0',
          right: '0',
          left: '0',
          zIndex: '5',
        }}
      >
        <Navbar></Navbar>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <div className="contents">
              {/* <h1
                className="wow fadeInDown"
                data-wow-duration="1000ms"
                data-wow-delay="0.3s"
              >
                Previmune <br /> Clínica de Vacinas
              </h1>
              <p
                className="lead  wow fadeIn"
                data-wow-duration="1000ms"
                data-wow-delay="400ms"
              ></p> */}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

export default HeaderSite;
