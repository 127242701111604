import 'bootstrap/dist/css/bootstrap.min.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePrevimuneContext } from '../../context/PrevimuneContext';

import '../animate.css';
import '../site.css';
import '../../main.css';
import '../responsive.css';
import './indexPage.css';

//import logoGrande from '../../imagens/img/logoGrande.png';
import logoGrande from '../../imagens/img/logos/logoGrande_original.png';

import { BuscaToken, consultaApi } from '../../api/consultaApi';

const Index = () => {
  const navigate = useNavigate();

  const { filiais, setFiliais, selectedFilial, setSelectedFilial } =
    usePrevimuneContext();

  const handleSelectChange = (event) => {
    const valorSelecionado = parseInt(event.target.value); //Pega valor selecionado no dropdown
    setSelectedFilial(valorSelecionado);
  };

  const salvaFilialLogada = async () => {
    if (!!selectedFilial) {
      localStorage.setItem('filial', selectedFilial);
      setSelectedFilial(selectedFilial);
    } else {
      console.log('Nenhuma filial definida.');
    }
  };

  const handleButtonClick = async () => {
    await salvaFilialLogada();
  };

  //Ao iniciar página
  useEffect(() => {
    const AtualizaDados = async () => {
      try {
        //await BuscaToken();

        buscaApi('get', '/Filiais');
      } catch (error) {
        console.log('Erro ao atualizar dados na página', error);
      }
    };

    AtualizaDados();
  }, []);

  const buscaApi = async (metodo, rota, dados = null) => {
    try {
      const response = await consultaApi(metodo, rota, dados);
      if (response.status === 200) {
        const dadosApi = await response.data;
        setFiliais(dadosApi);

        if (dadosApi.length > 0) {
          setSelectedFilial(dadosApi[0].IDfilial);
        }
      } else {
        console.log(
          'Retorno não esperado: ',
          response.status,
          response.statusText
        );
      }
    } catch (error) {
      console.log('Erro ao consultar Api', error); //debug
    }
  };

  return (
    <>
      {' '}
      {/*React.Fragment*/}
      <div className="IndexPageDiv">
        <div className="indexFront">
          {/* <div className="indexLogo">
            <img className="logo" src={logoGrande} alt="logo" />
          </div> */}

          <div className="div-container">
            <div className="selectAreaFranquia wrap-loginArea">
              <p>Seja um Franqueado:</p>
              <Row>
                <Col md={12} align="center">
                  <button
                    onClick={() => {
                      navigate('/Franqueado');
                    }}
                    style={{
                      padding: '7px',
                      backgroundColor: '#E76114',
                      borderRadius: '20px',
                      color: 'white',
                      borderColor: '#E76114',
                      cursor: 'pointer',
                      width: '150px',
                      whiteSpace: 'nowrap',
                    }}
                    id="btn_ir"
                    type="button"
                  >
                    TENHO INTERESSE
                  </button>
                </Col>
              </Row>
            </div>

            <br />
            <br />
            <div className="selectAreaFranquia wrap-loginArea">
              <p>Selecione a unidade mais próxima: </p>
              <Row>
                <Col md={12} align="center">
                  <select
                    onChange={(e) => handleSelectChange(e)}
                    style={{ borderRadius: '10px', width: '98%' }}
                    id="dropdown"
                  >
                    {filiais.length > 0 &&
                      filiais.map((filial) => (
                        <option key={filial.IDfilial} value={filial.IDfilial}>
                          {filial.nome}
                        </option>
                      ))}
                  </select>
                </Col>
              </Row>
              <Row>
                <Col md={12} align="center">
                  <input
                    onClick={() => {
                      handleButtonClick();
                      navigate('/Home');
                    }}
                    style={{
                      padding: '5px',
                      backgroundColor: '#E76114',
                      borderRadius: '20px',
                      color: 'white',
                      borderColor: '#E76114',
                      cursor: 'pointer',
                    }}
                    id="btn_ir"
                    type="button"
                    value="IR PARA O SITE"
                  />
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Index;
