import { consultaApi } from './consultaApi';

async function BuscaProdutosApi(IDFilial = null, IDProduto = null) {
  let response;
  try {
    //await BuscaToken();
  } catch (error) {
    console.log('Erro ao consultar token', error);
  }
  try {
    const rota =
      '/Produtos' +
      (IDProduto
        ? 'ID/' + IDFilial + '/' + IDProduto
        : IDFilial
        ? '/' + IDFilial
        : '');
    response = await consultaApi('get', rota);

    if (response.status === 200) {
      return response.data;
    } else {
      console.log(
        'Retorno não esperado:',
        response.status,
        response.statusText
      );
      return response;
    }
  } catch (error) {
    console.log('Erro ao consultar produtos', error);
    return null;
  }
}

export default BuscaProdutosApi;
