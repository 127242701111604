import { api } from '../services/api';

// async function BuscaToken() {
//console.log('Buscando Token...');
// let response = await api.post('/get-token', {
//   user: 'xxtgs627683yshjsb872gd_nix*',
//   pwd: 'kkbecuix_jwedbxiw62734782bxib9823gsdgxxx_',
// });
// try {
//   api.defaults.headers.common['x-access-Authorization'] = response.data.token;
//   //const newToken = response.data.token;
//   //setToken(newToken);
//   //console.log('token: ', api.defaults.headers.common['x-access-token']);
//   return response.data.token;
// } catch (error) {
//   console.log('erro ao buscar Token: ', error);
// }
// }

async function consultaApi(metodo, rota, dados = null) {
  //console.log('configAPI: ', metodo, decodeURIComponent(rota), dados);
  const config = {
    metodo,
    url: rota,
    data: dados,
  };

  try {
    return await api(config)
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        console.log(error);
        return error;
      });
    //console.log('Consulta na API:', response);
  } catch (error) {
    console.log('erro em consulta API: ', error);
    return null;
  }
}
export { consultaApi };
