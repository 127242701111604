import React from 'react';
import { Outlet } from 'react-router-dom';
import HeaderSite from '../../components/layout/HeaderMenu';
import './style.css';

const Menu = () => {
  return (
    <div
      style={{
        backgroundColor: 'rgb(243, 240, 231)',
        padding: '2%',
        width: '100%',
        height: '135vh',
      }}
    >
      <div className="row">
        <HeaderSite></HeaderSite>
        <div className="col-sm-9" style={{ padding: '1em' }}>
          <div className="content">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
