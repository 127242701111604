import React from 'react';
import HeaderSite from '../../components/layout/HeaderSite';
//import Contact from "../../components/layout/Contact";
import Footer from '../../components/layout/Footer';
import GoToTop from '../../components/layout/GoToTop';
import CartaoVacina from '../../components/layout/cartaoVacina/CartaoVacina.jsx';
import Carrinho from '../../components/layout/carrinho/Carrinho';
import CarrinhoBotao from '../../components/layout/carrinhoBotao/CarrinhoBotao';

import { usePrevimuneContext } from '../../context/PrevimuneContext';

import '../../main.css';
import { Navigate } from 'react-router-dom';
//import { CartProvider } from "../../context/CarrinhoContext";

// function AddCarrinho() {
//   const num = localStorage.getItem("Nlista");
//   const itens = "";
//   for (let i = 1; i <= parseInt(num); i++) {
//     itens = itens + localStorage.getItem(i) + "_";
//   }
//   window.location =
//     `../Home/carrinho?id=` +
//     localStorage.getItem("ID_filial") +
//     `&lista=` +
//     itens;
// }

const filialLogada = localStorage.getItem('filial');

const Vacinas = () => {
  if (filialLogada <= 0) {
    Navigate('/Index');
  }

  return (
    <>
      <HeaderSite />
      <section id="vacinas" className="section">
        <div className="section-header">
          <h2
            className="section-title wow fadeIn"
            data-wow-duration="1000ms"
            data-wow-delay="0.3s"
          >
            Catálogo de Vacinas
          </h2>
          <hr className="lines wow zoomIn" data-wow-delay="0.3s" />
          <p
            className="section-subtitle wow fadeIn"
            data-wow-duration="1000ms"
            data-wow-delay="0.3s"
          >
            Selecione a(s) vacina(s) desejada(s) e monte seu pedido.
            <br />
            Após selecionar todos os produtos, clique no botão Concluir Pedido
            para ser encaminhado à finalização da compra.
            <br />
            Parcelamos em até 12x, consultar valores.
          </p>
          <br />
        </div>

        <section className="catalogo" id="catalogo" align="center">
          <CartaoVacina />
        </section>

        <div align="center">
          {/* <div
            className="compras-vacinas"
            style={buttonCarrinhoStyle}
            align="center"
          >
            <a href="#">
              {//href={Carrinho()}}
              <img className="carrinho" src={iconCarrinho} />
              <ol>
                <li style={{ textDecoration: "none" }}>
                  Concluir <br /> Pedido
                </li>
              </ol>
            </a>
          </div> */}

          {/*
          <button type="button" className="carrinhoPedido" align="center">
            <BsCart4 />
            <span className="legenda">Concluir Pedido</span>
            <span className="cart-status">2</span>
          </button>
        */}

          <CarrinhoBotao legenda={'Concluir Pedido'} />
        </div>
      </section>

      <Carrinho />

      <GoToTop />
      {/* <Contact /> */}
      <Footer />
    </>
  );
};

export default Vacinas;
